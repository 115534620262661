import React, { FC, useEffect } from 'react';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import UnlockOneHubAccounts from './UnlockOneHubAccounts';
import UnlockDirectDocument from './UnlockDirectDocument';

interface IUnlockRecipientsModalProps {
    show: boolean;
    lockedEmailAddresses: string[];
    directAccessLockedEmailAddresses: string[];
    isLoading: boolean;
    onCancel(): void;
    onUnlockOneHubAccount(emailAddress: string[]): void;
    onUnlockDirectDocument(emailAddress: string[], signingId: number): void;
    signingId: number;
}

const tabKeyNames = {
    directDocument: 'directDocumentTab',
    oneHub: 'oneHubTab',
};

export const UnlockRecipientsModal: FC<IUnlockRecipientsModalProps> = props => {
    const [activeKey, setActiveKey] = React.useState(tabKeyNames.directDocument);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        if (props.directAccessLockedEmailAddresses.length) {
            setActiveKey(tabKeyNames.directDocument);
        } else if (props.lockedEmailAddresses.length) {
            setActiveKey(tabKeyNames.oneHub);
        }

        if (props.lockedEmailAddresses.length || props.directAccessLockedEmailAddresses.length) {
            setIsLoading(false);
        }
    }, [props.lockedEmailAddresses, props.directAccessLockedEmailAddresses]);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    const onCancel = () => {
        setActiveKey(tabKeyNames.directDocument);
        props.onCancel();
    };

    const onUnlockDirectDocument = (emailAddress: string) => {
        props.onUnlockDirectDocument([emailAddress], props.signingId);
    };

    const unlockAll = () => {
        props.directAccessLockedEmailAddresses.length &&
            props.onUnlockDirectDocument(props.directAccessLockedEmailAddresses, props.signingId);
        props.lockedEmailAddresses.length && props.onUnlockOneHubAccount(props.lockedEmailAddresses);
    };

    const canUnlockAll =
        props.lockedEmailAddresses.length > 0 && props.directAccessLockedEmailAddresses.length > 0;

    return (
        <>
            <Modal
                centered={true}
                show={props.show}
                className="unlock-recipients-modal"
                onHide={onCancel}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="unlock-recipients-header">Unlock Recipient(s)</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Tabs
                        className="custom-tab"
                        fill
                        id="unlock_recipients"
                        activeKey={activeKey}
                        onSelect={k => setActiveKey(k!)}
                    >
                        {/* Direct Document Tab */}
                        <Tab
                            eventKey={tabKeyNames.directDocument}
                            title={
                                <div>
                                    <span className="view-acces-code-tab">Direct Document</span>
                                </div>
                            }
                            style={{ overflowX: 'hidden' }}
                        >
                            <UnlockDirectDocument
                                show={props.show}
                                lockedEmailAddresses={props.directAccessLockedEmailAddresses}
                                onUnlockDirectDocument={onUnlockDirectDocument}
                                isLoading={isLoading}
                                showUnlockAll={canUnlockAll}
                            />
                        </Tab>
                        {/* OneHub Tab */}
                        <Tab
                            eventKey={tabKeyNames.oneHub}
                            title={
                                <div>
                                    <span className="view-acces-code-tab">Client Portal</span>
                                </div>
                            }
                            style={{ overflowX: 'hidden' }}
                        >
                            <UnlockOneHubAccounts
                                show={props.show}
                                lockedEmailAddresses={props.lockedEmailAddresses}
                                onUnlockOneHubAccount={props.onUnlockOneHubAccount}
                                isLoading={isLoading}
                                showUnlockAll={canUnlockAll}
                            />
                        </Tab>
                    </Tabs>
                </Modal.Body>

                <Modal.Footer>
                    <Row style={{ display: 'contents' }}>
                        <Col className="col-styles">
                            <Button onClick={unlockAll} className="unlock-all-button" disabled={isLoading || !canUnlockAll}>
                                <span className="unlock-all-button-text">Unlock All</span>
                            </Button>
                        </Col>
                        <Col sm={4} className="col-styles">
                            <Button onClick={onCancel} className="close-button">
                                <span className="close-button-text">Close</span>
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};
