

export const ResourceIdConstants = {
    Archive: '_H_ARCHIVE',
    Download: '_G_DNLDDOC',
    Delete: '_G_DEL',
    SetAccessInSignature: 'SIGSR_G_SETACS',
    SetAccess:'_G_SETACS',
    SendForSignature:'SIGSFS_L_SNDSGN',
    DraftResume:'_G_RESUME',
    DevSection:'SIGS_L_DEVSEC',
    SendReminder:'_G_SNDRMDR',
    ResendAccess: '_G_REACSLNK',
    Restore:'_H_RESTORE',
    Cancel: '_G_CNCLDOC',
    DocumentNote: '_G_DOCNOTE',
    ClientTracking: '_G_CLITRACK',
    ViewAccessCode: '_G_VIEWACSCD',
    ReportAProblem: '_G_REPPROB',
    EditClientDetails: '_G_EDTCLIDET',
    ExportExcel: '_TB_EXPORT',
    GeneralSettings: '_L_GENSETTINGS',
    AssignTo: '_G_ASSIGNTO',
    Unlock: '_G_UNLOCK',
    ChangeOfficeLocation: '_G_CHNGOFCLOC',
    UnlockDirectAccessLockedEmails: '_G_UNLCKRECP',
}

export const ScreenIds = {
    SignatureDraft: 'SIGDR',
    SignatureReport: 'SIGSR',
    ArchiveReport: 'SIGAR',
    RecycleBin: 'SIGRB',
    Settings:'SIGS',
    Locked: 'SIGLR'
}

export const RBACInfo = {
    NoPermission: 'You do not have permission to perform this operation, please contact your system administrator for more information.',
    NoPermissionTitle: "You do not have permission to perform this operation,\nplease contact your system administrator for more\ninformation."
}