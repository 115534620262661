import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormControl from 'react-bootstrap/FormControl';
import Select, { Creatable, Option } from 'react-select';
import { Guid } from '../../../Core/Utilities/Guid';
import { ISecuritySettings } from '../../models/SignatureFlowSettings';
import { AuthenticationType, ClientType, IClient, IContact, initialClient, SigningType } from '../../models/SigningInfo';
import { ISendForSignatureData } from '../../store/SFStore';
import { IAuthenticationQuestionDictionary } from '../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import { PhoneNumberComponent } from '../common/PhoneNumberComponent';
import PhoneInput from 'react-phone-input-2';
import { authenticationOptions, clientOptions, recipientOptions, ValidationContants } from '../helper/Constants';
import { GetCountryCode, getHighestSOUptoLastCC, findLastIndex } from '../helper/HelperFunctions';
import { VenusNotifier } from '../helper/VenusNotifier';
import { QuestionAnswerPopup } from '../sendForSignature/QuestionAnswerPopup';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { ISignatureFlowReportDocument, SignatureFlowReportSignatureStatus } from '../../models/SignatureFlowReportState';
import { DashCircle, DropdownArrow, GripVertical, BigInfoIcon, XLg } from '../svg/CustomSvgIcons';
import { RadioButtonComponent } from '../common/RadioButtonComponent';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as Constants from '../helper/Constants';
import { keyCodes } from '../helper/Constants';

const arrowRenderer = () => {
    return <div> </div>
}

const highlightStyle: React.CSSProperties = {
    backgroundColor: '#d1ecf1'
}

interface IClientAuthenticationListProp {
    contacts: IContact[];
    recipients: IClient[];
    disableRecipientType?: boolean;
    recipientDeletable: boolean;
    clientDetailsMode: ClientDetailsMode;
    securitySettings: ISecuritySettings;
    questions: IAuthenticationQuestionDictionary;
    updateRecipient: (recipient: IClient, clientTypeUpdated?: boolean,signingOrderUpdate? : boolean) => void;
    updateRecipients?: (recipients: IClient[], updateSigningOrder: boolean, isDeleted?: boolean) => void;
    clientAuthenticationData: ISendForSignatureData;
    isEditable: boolean;
    signatureReport?: ISignatureFlowReportDocument;
    getAuthenticationParameter: (email: string, authenticationType: AuthenticationType, callback: () => void, question?: string) => void;
    signingType: SigningType;
    handleSigningTypeChange?: (signingType: SigningType) => void;
}

interface IClientAuthenticationListState {
    contacts: IContact[];
    selectedRecipient: IClient;
    showQuestionAnswerPopup: boolean;
}

export enum ClientDetailsMode {
    None = 0,
    Create = 1,
    Edit = 2
}

export class ClientAuthenticationList extends React.Component<IClientAuthenticationListProp, IClientAuthenticationListState>{

    constructor(props: any) {
        super(props);
        this.state = {
            contacts: [],
            selectedRecipient: initialClient,
            showQuestionAnswerPopup: false,
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps: IClientAuthenticationListProp) {
        let contacts: IContact[] = [];

        if (this.state.contacts.length !== nextProps.contacts.length) {
            contacts = nextProps.contacts;
            this.setState({ contacts: contacts });
        }
    }

    showQuestionAnswerPopup = (recipient: IClient) => {
        let selectedRecipient = recipient;
        this.setState({ showQuestionAnswerPopup: true, selectedRecipient: selectedRecipient })
    }

    clearAuthentication = (recipient: IClient) => {
        recipient.authenticationType = AuthenticationType.None;
        recipient.countryCode = '';
        recipient.mobileNumber = '';
        recipient.first4SSN = '';
        recipient.last4SSN = '';
        recipient.question = '';
        recipient.answer = '';
        return recipient;
    }

    onDeleteRecipientRow = (index: number) => {
        let recipients = this.props.recipients;

        if (recipients.length === 1 || recipients.length === 0) {
            let recipient = this.props.recipients[index];
            recipient.emailAddress = "";
            recipient.signingOrder = 1;
            recipient.clientType = ClientType.Undefined;
            recipient = this.clearAuthentication(recipient);
            this.props.recipients[index] = recipient;
            recipients = this.props.recipients;
        }
        else {
            const isLastRecipientCC = recipients[recipients.length - 1].clientType == ClientType.CC;
            recipients.splice(index, 1);
            recipients = recipients;
        }
        this.props.updateRecipients && this.props.updateRecipients(recipients, true, true);
    }

    populateEmailOptions = (): { value: string, label: string }[] => {
        let options: { value: string, label: string }[] = [];
        for (let key in this.state.contacts) {
            options.push({ value: key, label: this.state.contacts[key].emailAddress });
        }
        for (let key in this.props.recipients) {
            if (options.find(e => e.label === this.props.recipients[key].emailAddress) === undefined &&
                this.props.recipients[key].emailAddress !== "") {
                options.push({ value: options.length.toString(), label: this.props.recipients[key].emailAddress });
            }
        }
        return options;
    }

    getCountryCodeTitle = (countryCode: string) => {
        return GetCountryCode().find(i => i.value == countryCode)?.label;
    }

    populateQuestionOptions = (): { value: string, label: string }[] => {
        let options: { value: string, label: string }[] = [];
        for (let key in this.props.questions) {
            options.push({ value: key, label: this.props.questions[key].authenticationQuestion.text });
        }
        for (let key in this.props.recipients) {
            if (this.props.recipients[key].authenticationType.toString() === AuthenticationType.QuestionAnswer.toString() &&
                options.find(m => m.label === this.props.recipients[key].question) === undefined) {
                var newKey = parseInt(options[options.length-1].value)+parseInt(key)+1
                options.push({
                    value:newKey.toString(),
                    label: this.props.recipients[key].question as string
                });
            }
        }
        return options;
    }

    addEmail = (recipient: IClient, event: any) => {
        let contact: IContact = {
            emailAddress: event.label
        }
        let contacts = this.state.contacts;
        contacts.push(contact);
        this.setState({ contacts: contacts });
        this.handleEmailChange(recipient, event.label);
    }

    getSSN = (recipient: IClient): any => {
        switch (recipient.authenticationType.toString()) {
            case AuthenticationType.First4SSN.toString(): return recipient.first4SSN ? recipient.first4SSN : "";
            case AuthenticationType.Last4SSN.toString(): return recipient.last4SSN ? recipient.last4SSN : "";
        }
    }

    handleEmailChange = (recipient: IClient, email: string) => {


        let duplicateEmails = this.props.recipients.filter(r => r.emailAddress.toLowerCase() === email.toLowerCase());
        let oneEmailRepeated = false;
        this.props.recipients.forEach((r: IClient) => {
            if (this.props.recipients.filter(e => e.emailAddress.toLowerCase() === r.emailAddress.toLowerCase()).length === 2 &&
                r.emailAddress !== '') {
                oneEmailRepeated = true;
                return;
            }
        });

        if (duplicateEmails !== undefined && duplicateEmails.length === 2 && email !== "" ||
            (oneEmailRepeated &&
                this.props.recipients.find(r => r.emailAddress.toLowerCase() === email.toLowerCase() && email !== '') != undefined)) {
            if (recipient.emailAddress.toLowerCase() !== email.toLowerCase()) {
                VenusNotifier.Warning(ValidationContants.DuplicateEmail, "Warning");
            }
        }
        else {
            recipient.emailAddress = email;
            let updateSignerOrder: boolean = false;
            if (this.props.clientDetailsMode === ClientDetailsMode.Create) {

                if (recipient.clientType == ClientType.CC) {
                    const nonCCRecipients = this.props.recipients.filter(r => r.clientType != ClientType.CC);
                    recipient.signingOrder = nonCCRecipients.length > 0 ? nonCCRecipients[nonCCRecipients.length - 1].signingOrder + 1 : 1;
                    updateSignerOrder = true;

                    if(this.props.signingType == SigningType.Parallel) {
                        recipient.signingOrder = 1;
                    }
                }
                recipient.clientType = ClientType.Undefined;
            }
            recipient = this.clearAuthentication(recipient);
            this.props.updateRecipient(recipient, false, updateSignerOrder);
        }
    }


    handleCountryCodeChange = (recipient: IClient, value: any) => {
        recipient.countryCode = value ? '+' + value : "";
        this.props.updateRecipient(recipient);
    }

    handlePhonenumberChange = (recipient: IClient, event: any) => {
        recipient.mobileNumber = event ? event : "";
        this.props.updateRecipient(recipient);
    }

    handleSSNChange = (recipient: IClient, event: any) => {
        const value = event ? event.target.value : "";
        AuthenticationType[recipient.authenticationType] === AuthenticationType[AuthenticationType.First4SSN] ?
            recipient.first4SSN = value :
            recipient.last4SSN = value
        this.props.updateRecipient(recipient);
    }

    handleQuestionChange = (recipient: IClient, event: any) => {
        const id = event ? event.value : 0;
        const questions=this.populateQuestionOptions();
        recipient.question = questions.find(i=>i.value==id).label;
        recipient.answer = '';
        this.getAuthenticationParam(recipient, false);
        this.props.updateRecipient(recipient);
    }

    handleAnswerChange = (recipient: IClient, event: any) => {
        recipient.answer = event ? event.target.value : '';
        this.props.updateRecipient(recipient);
    }

    handleNameChange = (recipient: IClient, value: any) => {
        if(value.length <= 100){
            recipient.name = value;
            this.props.updateRecipient(recipient);
        }
    }
    
    handleRecipientTypeChange = (recipient: IClient, event: any, index: number) => {
        let updateSigningOrder = true;
        if (this.props.signingType == SigningType.Parallel) {
            // cc recipient signing order is 2 in parallel signing to keep cc's after signers but before empty recipient
            recipient.signingOrder = event?.value == ClientType.CC ? 2 : 1;
        }
        else {
            const firstCCIndex = this.props.recipients.findIndex(r => r.clientType == ClientType.CC);
            
            // empty to signer, cc to signer
            if ((recipient.clientType == ClientType.Undefined || recipient.clientType == ClientType.CC) && event?.value == ClientType.Signer) {
                if(firstCCIndex > -1){
                    if (index >= firstCCIndex) {
                        recipient.signingOrder = getHighestSOUptoLastCC(this.props.recipients) + 1;
                        this.props.recipients.splice(index, 1);
                        this.props.recipients.splice(firstCCIndex, 0, recipient); 
                    } 
                }
            }
            // empty to cc, signer to cc
            else if ((recipient.clientType == ClientType.Undefined || recipient.clientType == ClientType.Signer) && event?.value == ClientType.CC) {
                if (firstCCIndex > -1) {
                    this.props.recipients.splice(index, 1);
                    this.props.recipients.splice(firstCCIndex, 0, recipient); 
                }
                else // cc's are not present in list, append at the end
                {
                    this.props.recipients.splice(index, 1);
                    this.props.recipients.push(recipient);
                }
            }
        }
        recipient.clientType = event ? event.value : ClientType.Undefined;
        this.props.updateRecipient(recipient, true, updateSigningOrder);
    }

    handleSigningtypeChange = (event: any) => {
        this.props.handleSigningTypeChange && this.props.handleSigningTypeChange(parseInt(event.target.value));
    }

    handleSequenceNoChange = (recipient: IClient, event: any, focusOut : boolean) => {
        event.preventDefault();
        if (event.target.value === "0" && focusOut) {
            VenusNotifier.Warning(Constants.SFStep2Constants.validZeroSigningOrder, null);
            return;
        }	
        recipient.signingOrder = parseInt(event.target.value);
        focusOut ? this.props.updateRecipient(recipient,false,true) : this.props.updateRecipient(recipient,false);
    }

    handleSequenceonkeyDown = (recipient: IClient, event: any) => {
        const invalidCharacters = [
            keyCodes.Equal, 
            keyCodes.KeyE, 
            keyCodes.Minus, 
            keyCodes.NumpadAdd, 
            keyCodes.NumpadSubtract,
            keyCodes.NumpadDecimal,
            keyCodes.Period
        ]
        if (invalidCharacters.includes(event.keyCode)) {
            event.preventDefault();
        }
        if (event.keyCode === keyCodes.Enter) {
            recipient.signingOrder = parseInt(event.target.value);
            this.props.updateRecipient(recipient,false,true);
        }
    }

    handleAuthenticationTypeChange = (updatedRecipient: IClient, event: any) => {
        let recipient = updatedRecipient;
        recipient.authenticationType = event ? event.value : AuthenticationType.None;
        recipient.countryCode = recipient.countryCode === '+1' && recipient.mobileNumber === '' ? '' : recipient.countryCode;

        if (event && event.value === AuthenticationType.QuestionAnswer.toString() &&
            recipient.question === undefined || recipient.question === '' || recipient.question === null) {
            const defaultQuestion = this.props.questions[this.props.securitySettings.questionSettings.defaultQuestion] ?
                this.props.questions[this.props.securitySettings.questionSettings.defaultQuestion].authenticationQuestion.text :
                this.props.questions[parseInt(Object.keys(this.props.questions)[0])].authenticationQuestion.text;
            recipient.question = defaultQuestion;
        }
        if (event && event.value !== AuthenticationType.AccessCodeEmail.toString() && event.value !== AuthenticationType.KBA.toString()) {
            this.isAuthParameterExist(recipient.emailAddress, recipient.authenticationType) ?
                recipient = this.getExistingAuthParam(recipient) :
                this.getAuthenticationParam(recipient);
        }

        if(event && event.value === AuthenticationType.AccessCodeSMS.toString()){
            if(recipient.countryCode === '' && recipient.mobileNumber === ''){
                recipient.countryCode = '+1';
            }
        }

        this.props.updateRecipient(recipient);
    }


    getAuthenticationParam = (recipient: IClient, isUpdateRecipient = true) => {
        if (!this.isAuthParameterExist(recipient.emailAddress, recipient.authenticationType)) {

            recipient.authenticationType.toString() === AuthenticationType.QuestionAnswer.toString() ?
                this.props.getAuthenticationParameter(recipient.emailAddress, recipient.authenticationType,
                    () => { this.updateAuthenticationParam(recipient, isUpdateRecipient) }, recipient.question) :
                this.props.getAuthenticationParameter(recipient.emailAddress, recipient.authenticationType,
                    () => { this.updateAuthenticationParam(recipient) });
        }
    }

    updateAuthenticationParam = (recipient: IClient, isUpdateRecipient = true) => {
        let updatedRecipient = this.getExistingAuthParam(recipient);
        if(updatedRecipient.authenticationType.toString() === AuthenticationType.AccessCodeSMS.toString()){
            if(updatedRecipient.countryCode === '' && updatedRecipient.mobileNumber === ''){
                updatedRecipient.countryCode = '+1';
            }
        }
        isUpdateRecipient ? this.props.updateRecipient(updatedRecipient) : this.setState({ selectedRecipient: updatedRecipient });
    }

    getExistingAuthParam = (recipient: IClient): IClient => {
        const clientAuthenticationInfo = this.props.clientAuthenticationData.clientAuthenticationInfo[recipient.emailAddress];
        switch (recipient.authenticationType.toString()) {

            case AuthenticationType.AccessCodeSMS.toString():
                recipient.countryCode = clientAuthenticationInfo ? clientAuthenticationInfo.countryCode : '';
                recipient.mobileNumber = clientAuthenticationInfo ? clientAuthenticationInfo.mobileNumber : '';
                break;
            case AuthenticationType.First4SSN.toString():
                recipient.first4SSN = clientAuthenticationInfo ? clientAuthenticationInfo.first4SSN : '';
                break;
            case AuthenticationType.Last4SSN.toString():
                recipient.last4SSN = clientAuthenticationInfo ? clientAuthenticationInfo.last4SSN : '';
                break;
            case AuthenticationType.QuestionAnswer.toString():
                recipient.answer = (clientAuthenticationInfo && clientAuthenticationInfo.questionAnswer && recipient.question) ?
                    clientAuthenticationInfo.questionAnswer[recipient.question] : '';
                break;
        }
        return recipient;
    }

    isAuthParameterExist = (emailAddress: string, authenticationType: AuthenticationType, question?: string): boolean => {
        if (this.props.clientAuthenticationData && this.props.clientAuthenticationData.clientAuthenticationInfo &&
            this.props.clientAuthenticationData.clientAuthenticationInfo[emailAddress]) {

            const clientAuthenticationInfo = this.props.clientAuthenticationData.clientAuthenticationInfo[emailAddress];

            switch (authenticationType.toString()) {
                case AuthenticationType.AccessCodeSMS.toString():
                    return clientAuthenticationInfo.countryCode !== null &&
                        clientAuthenticationInfo.countryCode !== undefined;

                case AuthenticationType.First4SSN.toString():
                    return clientAuthenticationInfo.first4SSN !== null &&
                        clientAuthenticationInfo.first4SSN !== undefined;

                case AuthenticationType.Last4SSN.toString():
                    return clientAuthenticationInfo.last4SSN !== null &&
                        clientAuthenticationInfo.last4SSN !== undefined;
                case AuthenticationType.QuestionAnswer.toString():
                    let isExist = true;
                    if (clientAuthenticationInfo.questionAnswer && question) {
                        isExist = clientAuthenticationInfo.questionAnswer[question] !== null;
                    }
                    else {
                        isExist = false;
                    }
                    return isExist;

                case AuthenticationType.None.toString():
                    return true;
            }
        }
        return false;
    }

    getAuthenticationField = (recipient: IClient, isDuplicateEmail: boolean, index:number) => {
        const isEditable = this.props.isEditable;
        const questionOptions = this.populateQuestionOptions();
        const isSigned = this.props.clientDetailsMode === ClientDetailsMode.Create ? false : this.isSigned(recipient);

        const disableField = !isEditable || isSigned;

        switch (recipient.authenticationType.toString()) {
            case AuthenticationType.AccessCodeSMS.toString():
                return <Row>
                    <Col sm={12}>
                        <div>    
                            <PhoneInput
                                autoFormat={true}
                                data-test-auto="4EBA70CD-CF2C-45D4-BCB6-B56B63AA335A"
                                disabled={disableField}
                                value={recipient.countryCode}
                                country={'us'}
                                buttonStyle={isDuplicateEmail && !disableField ? highlightStyle : undefined}
                                onChange={(e) => { this.handleCountryCodeChange(recipient, e) }} />
                        </div>
                        <div className='phone-component'>
                            <PhoneNumberComponent
                                disabled={!isEditable || isSigned}
                                data-test-auto="EB9D1949-6846-413D-8606-FEEA4C77E20B"
                                phoneNumber={recipient.mobileNumber ? recipient.mobileNumber : ""}
                                style={isDuplicateEmail && !disableField ? highlightStyle : undefined}
                                handleChangePhoneNumber={(e) => { this.handlePhonenumberChange(recipient, e) }}
                                >
                            </PhoneNumberComponent>
                        </div> 
                    </Col>
                </Row>

            case AuthenticationType.First4SSN.toString():
            case AuthenticationType.Last4SSN.toString():
                return <Row>
                    <Col sm={12}>
                        <FormControl
                            type="text"
                            maxLength={4}
                            value={this.getSSN(recipient)}
                            className='borderRadius3 font14'
                            data-test-auto="8CD5E754-713E-4495-B500-04841619D130"
                            onChange={(event: any) => { this.handleSSNChange(recipient, event) }}
                            disabled={disableField}
                            placeholder={recipient.authenticationType.toString() === AuthenticationType.First4SSN.toString() ?
                                "Enter first four digits " : "Enter last four digits "}
                            style={isDuplicateEmail && !disableField ? highlightStyle : undefined}

                        />
                    </Col>
                </Row>

            case AuthenticationType.QuestionAnswer.toString():
                if (this.props.clientDetailsMode === ClientDetailsMode.Create) {
                    return <Row >
                        <Col sm={12} title={recipient.question} >
                            <p
                                className="qa-text"
                                data-test-auto="88A25C5A-95ED-4C48-B52F-006C72D4EAC2"
                                onClick={() => { this.showQuestionAnswerPopup(recipient) }} >
                                {recipient.question}
                            </p>
                        </Col>
                    </Row>
                }
                else {
                    return <>
                                <Row >
                                    <Col sm={12} title={questionOptions.find(m => m.label === recipient.question)!.label}>
                                        <Select
                                            disabled={disableField}
                                            options={questionOptions}
                                            className={this.dropUpRender(index) ? 'drop-up' : ''}
                                            name="form-field-question"
                                            data-test-auto="8AA9C33B-DB1F-4425-941B-CC3D6B96AE29"
                                            value={questionOptions.find(m => m.label === recipient.question)!.value}
                                            onChange={(e) => { this.handleQuestionChange(recipient, e) }}
                                            style={isDuplicateEmail && !disableField ? highlightStyle : undefined}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className='authentication-answer' style={this.getEditClientModalColumnWidth('authentication-answer')}>
                                        <FormControl
                                            type="text"
                                            disabled={disableField}
                                            value={recipient.answer}
                                            placeholder="Enter an answer"
                                            data-test-auto="92292A66-46C4-4C53-BB6C-D54737578192"
                                            onChange={(event: any) => { this.handleAnswerChange(recipient, event) }}
                                            style={isDuplicateEmail && !disableField ? highlightStyle : undefined}
                                            
                                            />
                                    </Col>
                                </Row>
                            </>    
                }

            case AuthenticationType.AccessCodeEmail.toString():
                return <div> </div>;
        }
    }

    onCancelButtonClick = () => {
        this.setState({ showQuestionAnswerPopup: false });
    }

    onSaveQuestion = (recipient: IClient) => {
        this.onCancelButtonClick();
        this.props.updateRecipient(recipient);
    }
    filterOption = ({ label }: Option, string: string) => {
        const words = string.split(" ");
        if (label) {
            if (!string) {
                return false;
            }
            return words.reduce(
                (acc, cur) =>
                    acc && label.toLowerCase().startsWith(cur.toLowerCase()),
                true
            );
        }
        return false;

    };

    isSigned = (recipient: IClient): boolean => {
        if (this.props.signatureReport &&
            this.props.signatureReport.signatureStatus !== SignatureFlowReportSignatureStatus.PartiallySigned &&
            this.props.signatureReport.signatureStatus !== SignatureFlowReportSignatureStatus.OutForSignature) {
            return true;
        }
        else if (this.props.signatureReport &&
            recipient.delegateeId !== undefined && recipient.delegateeId <= 0) {
            let index = this.props.signatureReport.signerInfo.findIndex(x => x.clientGuid === recipient.clientGuid.toString());
            return this.props.signatureReport.signerInfo[index] ? this.props.signatureReport.signerInfo[index].isSigned === 1 : false;
        }
        else if (this.props.signatureReport && recipient.delegateeId !== undefined && recipient.delegateeId > 0) {
            let delegateeId = recipient.delegateeId;
            let index = this.props.signatureReport.signerInfo.findIndex(x => x.clientId === delegateeId);
            return this.props.signatureReport.signerInfo[index] ? this.props.signatureReport.signerInfo[index].isSigned === 1 : false;
        }
        return false;
    }

    isDuplicateEmail = (recipient: IClient): boolean => {

        if (this.props.recipients.filter(r => r.emailAddress.toLowerCase() === recipient.emailAddress.toLowerCase()).length > 1 &&
            recipient.emailAddress !== '') {
            let firstOccurrenceIndex = this.props.recipients.findIndex(r => r.emailAddress.toLowerCase() ===
                recipient.emailAddress.toLowerCase());
            let currentRecipientIndex = this.props.recipients.findIndex(r => r.clientGuid == recipient.clientGuid);
            return currentRecipientIndex > firstOccurrenceIndex;
        }
        return false;
    }

    isRecipientInfoLoading = (recipient: IClient) => {
        if (recipient.authenticationType.toString() === AuthenticationType.First4SSN.toString() &&
            (recipient.first4SSN === undefined || recipient.first4SSN === null || recipient.first4SSN === '')) {
            return true;
        }
        if (recipient.authenticationType.toString() === AuthenticationType.Last4SSN.toString() &&
            (recipient.last4SSN === undefined || recipient.last4SSN === null || recipient.last4SSN === '')) {
            return true;
        }
        if (recipient.authenticationType.toString() === AuthenticationType.QuestionAnswer.toString() &&
            (recipient.answer === undefined || recipient.answer === null || recipient.answer === '')) {
            return true;
        }
        if (recipient.authenticationType.toString() === AuthenticationType.AccessCodeSMS.toString() &&
            (recipient.mobileNumber === undefined || recipient.mobileNumber === null || recipient.mobileNumber === '' )) {
            return true;
        }
        return false;
    }

    dropUpRender = (index: number) => {

        if(this.props.clientDetailsMode == ClientDetailsMode.Create &&this.props.recipients.length > 5){
            return (((index + 1) > (this.props.recipients.length - 2)) && (index > 3)) ? true : false;
        }
        else if(this.props.clientDetailsMode == ClientDetailsMode.Edit){
            return  true;
        }
        return false;
    }

    drager = (result: any, provided: any) => {
        if(!result.destination || result?.destination.index == result?.source.index) return;

        let highestSigningOrder = 1;
        let highestSOIndex = 0;
        this.props.recipients.forEach((recipient: IClient, index: number) => {
            if (recipient.clientType != ClientType.CC && recipient.signingOrder > highestSigningOrder) {
                highestSigningOrder = recipient.signingOrder;
                highestSOIndex = index;
            }
        });

        var resultantIndex = 0;

        if(result.destination.index  == 0) {
            resultantIndex = result.destination.index;
        } else if (this.props.recipients.length-1 == result.destination.index){
            resultantIndex = result.destination.index;
        }
        else if(result.source.index < result.destination.index){     
            resultantIndex = result.destination.index + 1;
        }
        else if(result.source.index > result.destination.index){
            resultantIndex = result.destination.index - 1;
        }

        var recipient = this.props.recipients[result.source.index];
        recipient.signingOrder = this.props.recipients[resultantIndex].signingOrder;
        this.props.updateRecipient(recipient,false,true);
    } 

    isCC = (index: number) => {
        return this.props.recipients[index].clientType == ClientType.CC;
    }

    getAuthenticationOptionsClassName = () => {
        return 'authentication-options padL00' + 
            ((this.props.clientDetailsMode === ClientDetailsMode.Edit) 
                ? (this.props.signingType == SigningType.Parallel 
                    ? ' marL15' 
                    : ' marL73') 
                : ''
            );
    }

    getEditClientModalColumnWidth = (type: string) => {
        if(this.props.clientDetailsMode === ClientDetailsMode.Edit && this.props.signingType == SigningType.Parallel){
            if(type == 'recipient-name'){
               return { maxWidth: '211px', paddingLeft: "15px"}; ;
            }
            else if(type == "recipient-email"){
                return {maxWidth: '238px', paddingLeft: "15px"};
            }
            else if(type == "recipient-type"){
                return {maxWidth: '127px',flex: '0 0 22%'};
            }
            else if(type == "authentication-options"){
                return {maxWidth: '293px', paddingLeft: "15px"};

            }
            else if(type == "authentication-type"){
                return {maxWidth: '275px', padding:'7.5px 0 0 0'};
            }
            else if (type == "authentication-answer"){
                return {maxWidth: '590px',marginLeft: '-292px'};
            }
        }

        return {};
    }

    private renderEmailValue = (option: any) => {
        return (
            <div className='email-value-render'>
                 <input
                    type="text"
                    value={option.label}
                    onChange={(e) => e.preventDefault()}
                    />
            </div>
        );
    }
      
    public render() {
        const emailOptions = this.populateEmailOptions();
        const isEditable = this.props.isEditable;
        const recipientColumns=["Email","Type","Authentication"]

        return <div>
            <Row sm={12} className='clientAuthenticaionContainer'>
                <Col sm={12} style={{ whiteSpace:'nowrap' }} className='padL00'>
                    <label className='clientAuthenticaionTitle'>{this.props.clientDetailsMode === ClientDetailsMode.Create ? "Recipients" : "Recipients Information"}</label>                        
                </Col>
                
                {this.props.clientDetailsMode === ClientDetailsMode.Create &&
                <>
                    <Col className='clientAuthenticationSigningSelection'  sm={12}>
                        <Row className='marB05'>
                            <Col xs={'auto'}  className='padL00' style={{paddingRight:'15px'}}>
                                <RadioButtonComponent
                                    id={"parallel-signing"}
                                    text={""}
                                    checked={this.props.signingType === SigningType.Parallel}
                                    onChange={this.handleSigningtypeChange}
                                    value={SigningType.Parallel}
                                    dataTestAuto={"a2f16a83-60db-4640-aae5-beb1c549a44f"}
                                >           
                                <label>Parallel Signing Sequence</label>
                                <label className='sequence-info-icon'><BigInfoIcon/> <label className='tooltip' style={{width:'365px'}}>All recipients receive and sign the files simultaneously.</label> </label>                     
                                </RadioButtonComponent>
                            </Col>
                            <Col sm={5}>
                                <RadioButtonComponent
                                    id={"sequential-signing"}
                                    text={""}
                                    checked={this.props.signingType === SigningType.Custom}
                                    onChange={this.handleSigningtypeChange}
                                    value={SigningType.Custom}
                                    dataTestAuto={"c7484e2d-3b21-4c66-8fe0-f70a5bfefaa1"}
                                >
                                <label>Custom Signing Sequence</label>
                                <label className='sequence-info-icon'><BigInfoIcon/><label className='tooltip'>Set the order in which recipients receive the files for signing.</label> </label>  
                                </RadioButtonComponent>
                            </Col>
                        </Row>
                    </Col>
                </>
                }
                <DragDropContext onDragEnd={(e,p) => this.drager(e,p)}>
                    <Droppable droppableId="recipients">
                        {(provided) => (
                            <Col sm={12}  id={'id-recipients-body'} {...provided.droppableProps} ref={provided.innerRef} className={"recipients-body " + (this.props.recipients.length > 5 ? "sfPanel-body" : "")}>
                                {this.props.recipients && this.props.recipients.map((recipient: IClient, index: number) => {
                                    const isLoading = this.props.clientAuthenticationData.clientAuthenticationInfo[recipient.emailAddress] ?
                                        this.props.clientAuthenticationData.clientAuthenticationInfo[recipient.emailAddress].isLoading &&
                                        this.isRecipientInfoLoading(recipient)
                                        : false;

                                    const isDelegated = recipient.delegateeId && recipient.delegateeId > 0 ? true : false;

                                    const isDisableLinkAuthentication =
                                        this.props.clientDetailsMode === ClientDetailsMode.Create ?
                                            (!this.props.securitySettings.enableSignerAuthentication || recipient.emailAddress === "" || !isEditable) :
                                            (recipient.emailAddress === "" || !isEditable);

                                    const isSigned = this.props.clientDetailsMode === ClientDetailsMode.Create ? false : this.isSigned(recipient);

                                    const isDuplicateEmail = this.isDuplicateEmail(recipient);

                                    const disableEmail = isDelegated || isSigned;
                                    const disableRecipientName = isSigned;
                                    const disableRecipientType = this.props.disableRecipientType || !isEditable || isSigned;
                                    const disableLinkAuthentication = isDisableLinkAuthentication || isSigned;
                                    return <Draggable key={'recipient' + index} draggableId={'recipient' + index} index={index} 
                                                      isDragDisabled ={this.props.signingType != SigningType.Custom || recipient.clientType == ClientType.CC}>
                                                {(provided,snapshot) =>(
                                                    <LoadingOverlay style={{ height:"auto" }} >
                                                        <Loader loading={isLoading} />
                                                            {this.props.clientDetailsMode === ClientDetailsMode.Edit ? 
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <label className='fontWeight700 fontSize14'>Recipient {index +1}</label>
                                                                    </Col>
                                                                </Row>
                                                            : null}      
                                                            <Row sm={12} className="form-group" 
                                                                ref={provided.innerRef} {...provided.draggableProps}
                                                                key={"sf-recipient" + recipient.clientGuid} id={'recipentNo.' + index}>
                                                                    { this.props.clientDetailsMode === ClientDetailsMode.Edit &&  this.props.signingType != SigningType.Parallel ?
                                                                        <Col sm={'auto'} className='padR00'>
                                                                            <div className={'recipent-moveable-box is-disabled' + ' recipenntNo.' + recipient.signingOrder} >
                                                                                <span><GripVertical/></span>
                                                                                <span><input type='number' disabled 
                                                                                value={recipient.signingOrder > 0 ? recipient.signingOrder : ""}
                                                                                min={1}/></span>
                                                                            </div>    
                                                                        </Col>
                                                                    :
                                                                    this.props.clientDetailsMode === ClientDetailsMode.Create &&  this.props.signingType == SigningType.Custom ?
                                                                        <Col sm={'auto'} className='padR00'>
                                                                            { recipient.clientType == ClientType.CC ?
                                                                                <div className={'recipent-moveable-box is-disabled' + ' recipenntNo.' + recipient.signingOrder} >
                                                                                    <span {...provided.dragHandleProps}  ><GripVertical/></span>
                                                                                    <span><input type='number' disabled value={recipient.signingOrder} min={1}/></span>
                                                                                    <label className={'tooltip ' + (index > 4 ? 'tooltip-inverted' : '')}>Reordering is disabled for CC recipients.</label>
                                                                                </div> 
                                                                                :
                                                                                <div className={'recipent-moveable-box' + ' recipenntNo.' + recipient.signingOrder} >
                                                                                    <span className='drag-handle' {...provided.dragHandleProps}  ><GripVertical/></span>
                                                                                    <span><input type='number' value={recipient.signingOrder} min={1}
                                                                                    onChange={(e: any) => { this.handleSequenceNoChange(recipient, e, false ) }}
                                                                                    onKeyDown={(e: any) => { this.handleSequenceonkeyDown(recipient, e) }}
                                                                                    onBlur={(e: any) => { this.handleSequenceNoChange(recipient, e, true) }}
                                                                                    /></span>
                                                                                </div>
                                                                            }
                                                                        </Col>                       
                                                                        :null
                                                                    }
                                                                    <Col sm={2} className="recipient-name" style={this.getEditClientModalColumnWidth('recipient-name')}>
                                                                        <FormControl
                                                                            type="text"
                                                                            placeholder="Enter recipient name"
                                                                            value={recipient.name} 
                                                                            disabled={disableRecipientName}
                                                                            onChange={(e) => this.handleNameChange(recipient, e.target.value)}
                                                                            className="recipient-name-field"
                                                                            style={isDuplicateEmail ? highlightStyle : undefined}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={3} className="recipient-email"  style={this.getEditClientModalColumnWidth('recipient-email')}>
                                                                        <Creatable
                                                                            ignoreCase={true}
                                                                            autosize={false}
                                                                            inputProps={{type:"text",placeholder: recipient.emailAddress ? null:"Enter email address",style:isDuplicateEmail ? highlightStyle : undefined}}
                                                                            closeOnSelect={true}
                                                                            noResultsText={""}
                                                                            filterOption={(option: Option, string: string) => { return this.filterOption(option, string) }}
                                                                            options={emailOptions}
                                                                            style={isDuplicateEmail ? highlightStyle : undefined}
                                                                            arrowRenderer={arrowRenderer}
                                                                            data-test-auto={Guid.newGuid()}
                                                                            className={this.dropUpRender(index) ? 'drop-up' : ''}
                                                                            id={index + "-recipient-email"}
                                                                            clearRenderer={()=><XLg color='#212529'/>}
                                                                            disabled={disableEmail}
                                                                            valueRenderer={this.renderEmailValue}
                                                                            promptTextCreator={(value) => { return value }}
                                                                            onNewOptionClick={(e) => { this.addEmail(recipient, e) }}
                                                                            shouldKeyDownEventCreateNewOption={(keyCode: any) => { return ([13].indexOf(keyCode) !== -1) }}
                                                                            value={emailOptions.findIndex(m => m.label == recipient.emailAddress)}
                                                                            onChange={(e: any) => { this.handleEmailChange(recipient, e ? e.label : "") }}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={2} className='recipient-type' style={this.props.clientDetailsMode === ClientDetailsMode.Create &&  this.props.signingType == SigningType.Custom ? {flex: '0 0 13%'} : this.getEditClientModalColumnWidth('recipient-type')}>
                                                                        <Select
                                                                            options={this.props.clientDetailsMode === ClientDetailsMode.Create ? recipientOptions : clientOptions}
                                                                            value={recipient.clientType}
                                                                            placeholder="Recipient Type"
                                                                            id={index + "-recipient-type"}
                                                                            clearRenderer={()=><XLg color='#212529'/>}
                                                                            className={this.dropUpRender(index) ? 'drop-up' : ''}
                                                                            arrowRenderer={()=> <span data-test-auto={"107A7448-8042-4EB1-AC01-4BD7A637ED77"+index}><DropdownArrow /></span>}
                                                                            data-test-auto={Guid.newGuid()}
                                                                            style={isDuplicateEmail && !disableRecipientType ? highlightStyle : undefined}
                                                                            disabled={disableRecipientType}
                                                                            onChange={(e) => { this.handleRecipientTypeChange(recipient, e, index) }}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={2} className={this.getAuthenticationOptionsClassName()} style={this.getEditClientModalColumnWidth('authentication-options')}>
                                                                        <Select
                                                                            options={authenticationOptions}
                                                                            data-test-auto={Guid.newGuid()}
                                                                            placeholder="Link Authentication"
                                                                            clearRenderer={()=><span data-test-auto={"12e93e9f-2cdd-4235-acb8-420a4b997b3c"+index}><XLg color='#212529'/></span>}
                                                                            id={index + "-link-authentication"}
                                                                            className={this.dropUpRender(index) ? 'drop-up' : ''}
                                                                            value={recipient.authenticationType}
                                                                            disabled={disableLinkAuthentication}
                                                                            arrowRenderer={()=> <span data-test-auto={"394D15DA-BD0A-4363-84A3-6309C36F550C"+index}><DropdownArrow /></span> }
                                                                            onChange={(e) => { this.handleAuthenticationTypeChange(recipient, e) }}
                                                                            style={isDuplicateEmail && !disableLinkAuthentication ? highlightStyle : undefined}
                                                                        />                                        
                                                                    </Col>  
                                                                    { recipient.authenticationType != AuthenticationType.None  && recipient.authenticationType != AuthenticationType.AccessCodeEmail ?
                                                                    <Col sm={2}  className='authentication-type padL00' style={this.getEditClientModalColumnWidth('authentication-type')}>
                                                                    {this.getAuthenticationField(recipient, isDuplicateEmail,index)}
                                                                    </Col>:null}
                                                                    {this.props.clientDetailsMode === ClientDetailsMode.Create &&
                                                                        <Col sm={'auto'}  className='padL00 padT03' style={{maxWidth: '38px'}}>
                                                                            <span data-test-auto="B617CE2A-5686-44CE-B760-449E1CE8BF13"
                                                                            onClick={() => { this.onDeleteRecipientRow(index) }}
                                                                            style={{ cursor: 'pointer', verticalAlign: 'middle' }}>
                                                                                <DashCircle fill='#CC4A43'/>
                                                                            </span>
                                                                    </Col>}
                                                            </Row>                                         
                                                    </LoadingOverlay>
                                                )}    
                                            </Draggable>     
                                })}
                                {provided.placeholder}
                            </Col>
                        )}
                    </Droppable>          
                </DragDropContext>              
            </Row>
            <QuestionAnswerPopup
                questions={this.props.questions}
                showState={this.state.showQuestionAnswerPopup}
                onHide={this.onCancelButtonClick}
                allowUsersToAdd={this.props.securitySettings.questionSettings.allowUsersToAdd}
                recipient={this.state.selectedRecipient}
                onSave={this.onSaveQuestion}
                getAuthenticationAnswer={this.getAuthenticationParam}
            />
        </div>
    }
}


