export enum actionTypes {
    // Post Auth ActionTypes
    POST_AUTH_STORE_API_LOADER,
    POST_AUTH_STORE_RECEIVE_DATA,
    LOGGED_OUT,
    POST_AUTH_STORE_LOADER,

    REQUEST_USER_ASSIGNMENTS,
    RECEIVE_USER_ASSIGNMENTS,

    REQUEST_COMPANY_ASSIGNMENTS,
    RECEIVE_COMPANY_ASSIGNMENTS,

    REQUEST_USER_ASSIGNMENTS_PAGES,
    RECEIVE_USER_ASSIGNMENTS_PAGES,

    REQUEST_COMPANY_ASSIGNMENTS_PAGES,
    RECEIVE_COMPANY_ASSIGNMENTS_PAGES,
    UPDATE_CLIENT_INFO,

    REQUEST_USER_PROFILE,
    RECEIVE_USER_PROFILE,
    RECEIVE_ADMIN_PROFILE,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PASSWORD,
    RECEIVE_USER_RESOURCES,

    RECEIVE_COMPANY_LOGO,
    RECEIVE_COMPANY_PROFILE,
    UPDATE_COMPANY_PROFILE,
    UPDATE_COMPANY_SIGNATURE,
    UPDATE_COMPANY_LOGO,
    DELETE_COMPANY_LOGO,

    UPDATE_COMPANY_WHITE_LOGO,
    RECEIVE_COMPANY_WHITE_LOGO,
    DELETE_COMPANY_WHITE_LOGO,

    REQUEST_DELIVERED_RETURNS,
    RECEIVE_DELIVERED_RETURNS,
    RESET_DELIVERED_RETURNS,

    REQUEST_DELIVERED_RETURNS_PAGES,
    RECEIVE_DELIVERED_RETURNS_PAGES,

    REQUEST_QUICK_REPORTS,
    RECEIVE_QUICK_REPORTS,

    REQUEST_QUICK_REPORTS_PAGES,
    RECEIVE_QUICK_REPORTS_PAGES,

    REQUEST_TAX_DOCUMENT,
    RECEIVE_TAX_DOCUMENT,
    RECEIVE_TAX_DOCUMENTS,
    UPDATE_TAX_DOCUMENT,
    SAVE_TAX_DOCUMENT,
    DELETE_TAX_DOCUMENT,
    DELETE_DELIVERED_TAX_DOCUMENT,
    CLEAR_TAX_DOCUMENT,
    ARCHIVE_TAX_DOCUMENT,
    ASSIGN_TAX_DOCUMENT,
    REQUEST_TAX_DOCUMENT_ACCESS,
    RECEIVE_TAX_DOCUMENT_ACCESS,
    APPLY_TAX_DOCUMENT_ACCESS,
    UPDATE_TAX_DOCUMENT_ASSIGNED_TO,
    REQUEST_TAX_DOCUMENT_CLIENT_TRACKING,
    RECEIVE_TAX_DOCUMENT_CLIENT_TRACKING,
    REQUEST_TAX_DOCUMENT_SIGNED_DETAILS,
    RECEIVE_TAX_DOCUMENT_SIGNED_DETAILS,
    REQUEST_TAX_DOCUMENT_ACCESS_CODE,
    RECEIVE_TAX_DOCUMENT_ACCESS_CODE,
    REQUEST_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS,
    RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS,
    REQUEST_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS,
    RECEIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS,
    REQUEST_TAX_DOCUMENT_DOWNLOAD_HISTORY,
    RECEIVE_TAX_DOCUMENT_DOWNLOAD_HISTORY,
    DELIVER_TAX_DOCUMENT,
    RECALL_TAX_DOCUMENT,
    SEND_FOR_REVIEW_TAX_DOCUMENT,
    APPROVE_FOR_DELIVERY_TAX_DOCUMENT,
    SEND_TO_ERO_TAX_DOCUMENT,
    SEND_REMINDER_TAX_DOCUMENT,
    ERROR_TAX_DOCUMENT,
    GENERATE_OTP_TAX_DOCUMENT,
    UPDATE_DOCUMENT_CUSTOM_COLUMN_VALUE,
    RESET_TAX_RETURNS,
    RESET_TAX_DOCUMENT,
    UPDATE_DOCUMENT_INITIAL_STATUS,
    NOTIFY_TAX_DOCUMENT,
    REQUEST_EXPIRE_DOCUMENT_COUNT,
    RECEIVE_EXPIRE_DOCUMENT_COUNT,
    ERROR_EXPIRE_TAX_DOCUMENT_COUNT,

    REQUEST_USER_LIST,
    RECEIVE_USER_LIST,
    RECEIVE_DEPENDENT_USER_LIST,
    REQUEST_USER_SETTINGS,
    RECEIVE_USER_SETTINGS,
    REQUEST_SIGNATURE_USER_SETTINGS,
    RECEIVE_SIGNATURE_USER_SETTINGS,
    REQUEST_MAILING_ADDRESS,
    RECEIVE_MAILING_ADDRESS,
    RECEIVE_DELEGATED_SIGNERS,
    ERROR_USER_SETTINGS,
    RECEIVE_USER_DOWNLOAD_SIGNATURE,
    ERROR_USER_DOWNLOAD_SIGNATURE,
    REQUEST_USER_NOTIFICATION_SETTINGS,
    RECEIVE_USER_NOTIFICATION_SETTINGS,
    ERROR_USER_NOTIFICATION_SETTINGS,

    SAVE_USER,
    DELETE_USER,
    UPDATE_USER,
    UPDATE_DEFAULT_CONTACT,
    ERROR_RECEIVE_USER,

    REQUEST_CUSTOM_COLUMN_SETTINGS,
    RECEIVE_CUSTOM_COLUMN_SETTINGS,
    REQUEST_UPDATE_CUSTOM_COLUMN,
    REQUEST_ADD_CUSTOM_COLUMN_VALUE,
    RECEIVE_ADDED_CUSTOM_COLUMN_VALUE,
    REQUEST_UPDATE_CUSTOM_COLUMN_VALUE,
    RECEIVE_UPDATED_CUSTOM_COLUMN_VALUE,
    DELETE_CUSTOM_COLUMN_HEADER,
    DELETE_CUSTOM_COLUMN_VALUES,
    ERROR_CUSTOM_COLUMN,
    ERROR_GENERAL_SETTINGS,


    RECEIVE_ADDED_DOCUMENT_TYPE_VALUE,
    REQUEST_DOCUMENT_TYPE_SETTINGS,
    RECEIVE_DOCUMENT_TYPE_SETTINGS,
    ERROR_DOCUMENT_TYPE,
    DELETE_DOCUMENT_TYPE_VALUES,
    RECEIVE_UPDATED_DOCUMENT_TYPE_VALUE,

    REQUEST_REPORT_FILTER,
    RECEIVE_REPORT_FILTER,
    UPDATE_REPORT_FILTER,
    SAVE_REPORT_FILTER,
    DELETE_REPORT_FILTER,
    ERROR_REPORT_FILTER,
    ADD_DEFAULT_FILTER,
    REMOVE_DEFAULT_FILTER,

    REQUEST_PDF_OBJECT,
    RECEIVE_PDF_OBJECT,
    ERROR_PDF_OBJECT,
    RESET_PDF_OBJECTS,

    REQUEST_COMPANY_SETTINGS,
    RECEIVE_COMPANY_SETTINGS,
    ERROR_COMPANY_SETTINGS,
    UPDATE_COMPANY_SETTINGS,
    UPDATE_COMPANY_SETTINGS_LOCAL,

    //////////////////////////////////////////Suite MFA Setting //////////////////////////////////////////
    
    REQUEST_MFA_SETTING,
    RECEIVE_MFA_SETTING,
    
    /////////////////////////////////////////////////////////////////////////////////////////////////////

    RECEIVE_SUITE_PASSWORD_POLICY_SETTINGS,

    REQUEST_TAX_AUTHORITIES,
    RECEIVE_TAX_AUTHORITIES,

    REQUEST_CUSTOM_TAX_AUTHORITIES,
    RECEIVE_CUSTOM_TAX_AUTHORITIES,

    REQUEST_AUTHORITY_RELATED_TAX_DOCUMENT_IDS,
    RECEIVE_AUTHORITY_RELATED_TAX_DOCUMENT_IDS,

    REQUEST_ARCHIVE_RETURNS,
    RECEIVE_ARCHIVE_RETURNS,
    RESET_ARCHIVE_RETURNS,

    REQUEST_ARCHIVE_RETURNS_PAGES,
    RECEIVE_ARCHIVE_RETURNS_PAGES,

    REQUEST_ARCHIVE_TAX_DOCUMENT,
    RECEIVE_ARCHIVE_TAX_DOCUMENT,
    RESTORE_ARCHIVE_TAX_DOCUMENT,
    REQUEST_ARCHIVE_TAX_DOCUMENT_CLIENT_TRACKING,
    RECEIVE_ARCHIVE_TAX_DOCUMENT_CLIENT_TRACKING,
    REQUEST_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS,
    RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_DOCUMENTS,
    REQUEST_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS,
    RECEIVE_ARCHIVE_TAX_DOCUMENT_DOWNLOADABLE_EFILE_FORMS,
    ERROR_ARCHIVE_TAX_DOCUMENT,
    RECEIVE_ARCHIVED_TAX_DOCUMENT_DOWNLOAD_HISTORY,
    REQUEST_ARCHIVED_TAX_DOCUMENT_DOWNLOAD_HISTORY,
    UPDATE_ARCHIVE_DOCUMENT_CUSTOM_COLUMN_VALUE,
    REQUEST_ARCHIVED_TAX_DOCUMENT_SIGNED_DETAILS,
    RECEIVE_ARCHIVED_TAX_DOCUMENT_SIGNED_DETAILS,

    REQUEST_MANUAL_ADDRESS_LIST,
    RECEIVE_MANUAL_ADDRESS_LIST,
    ERROR_MANUAL_ADDRESS_LIST,
    REQUEST_UPDATE_MANUAL_ADDRESS,
    RECEIVE_UPDATED_MANUAL_ADDRESS,
    ADD_MANUAL_ADDRESS,
    DELETE_MANUAL_ADDRESS,
    CLEAR_MANUAL_ADDRESS_STATUS,

    NOTIFICATION,

    REQUEST_SAVED_MESSAGES,
    RECEIVE_SAVED_MESSAGES,
    DELETE_SAVED_MESSAGE,

    REQUEST_CLIENT_MESSAGES,
    RECEIVE_CLIENT_MESSAGES,
    DELETE_CLIENT_MESSAGE,

    REQUEST_WELCOME_MESSAGES,
    RECEIVE_WELCOME_MESSAGES,
    DELETE_WELCOME_MESSAGE,

    REQUEST_EMAIL_MESSAGES,
    RECEIVE_EMAIL_MESSAGES,
    DELETE_EMAIL_MESSAGE,
    REQUEST_START_IPRESTRICTION,
    RECEIVE_CURRENT_IPADDRESS,

    REQUEST_IPRESTRICTION_LIST,
    RECEIVE_IPRESTRICTION_LIST,
    ERROR_IPRESTRICTION,
    RESET_IPRESTRICTION,
    HIDE_LOADING_IPRESTRICTION,
    ADD_ROW_CHANGE_IPRESTRICTION,
    EDIT_ROW_CHANGE_IPRESTRICTION,
    SET_EDIT_ROW_IPRESTRICTION,

    REQUEST_INUSE_RETURNS,
    RECEIVE_INUSE_RETURNS,

    RECEIVE_INUSE_RETURNS_PAGES,
    REQUEST_INUSE_RETURNS_PAGES,
    MAKE_AVAILABLE_INUSE_TAX_DOCUMENT,


    REQUEST_RECYCLE_RETURNS,
    RECEIVE_RECYCLE_RETURNS,

    RECEIVE_RECYCLE_RETURNS_PAGES,
    REQUEST_RECYCLE_RETURNS_PAGES,

    REQUEST_VOUCHER_REMINDER_REPORT,
    RECEIVE_VOUCHER_REMINDER_REPORT,

    RECEIVE_VOUCHER_REMINDER_REPORT_PAGES,
    REQUEST_VOUCHER_REMINDER_REPORT_PAGES,

    REQUEST_ATTACHMENTS,
    RECEIVE_ATTACHMENTS,

    REQUEST_TAXPAYERVIEW_LOADER,

    K1_INSTRUCTION_DETAIL,

    REQUEST_DOWNLOAD_ATTACHMENTS,
    RECEIVE_DOWNLOAD_ATTACHMENTS,
    REQUEST_DOWNLOAD_K1DOCUMENT_LINK_ASYNC,
    RECEIVE_DOWNLOAD_K1DOCUMENT_LINK_ASYNC,

    SET_VOUCHER_DUE_DATE_REMINDER,
    ERROR_SET_REMINDER,
    REQUEST_TAXCADDY_LOOKUP_DETAILS,
    CLEAR_TAXCADDY_LOOKUP_DETAILS,

    RECEIVE_VOUCHER_DUE_DATE_LIST,
    RECEIVE_PAYMENT_URL_INFO_LIST,
    RECEIVE_VOUCHER_FORM_NAME_LIST,

    RECEIVE_K1DOCUMENT_SETTINGS,

    REQUEST_BUSINESS_RETURN_INSTRUCTIONS,
    RECEIVE_BUSINESS_RETURN_INSTRUCTIONS,
    REPLACE_BUSINESS_RETURN_INSTRUCTION_START,
    REPLACE_BUSINESS_RETURN_INSTRUCTION_FINISH,

    RECEIVE_NEXT_SIGNER_DETAILS,

    UNLOCK_LOCKED_DOCUMENT,

    REQUEST_TAX_RETURN,
    RECEIVE_TAX_RETURN_LINK,
    PROCESS_TAX_RETURN,
    UPDATE_TAX_RETURN,

    REQUEST_ASSIGN_USER,
    RECEIVE_ASSIGN_USER,

    RECEIVE_TAX_DOCUMENT_STATUS,

    REQUEST_USER_LOGIN_HISTORY,
    RECEIVE_USER_LOGIN_HISTORY,

    RECEIVE_DEFINED_PASSWORD_POLICY,
    RECEIVE_COMPANY_LEDGER,
    VALIDATE_AD_AZURE_GROUP,
    REFRESH_TAX_DOCUMENT_STORE,
    CHECK_TAXDOCUMENT_EXIST,

    UPDATE_EXTERNAL_AUTHENTICATION_SETTINGS,

    REQUEST_K1_DELIVEREDSTATUS_DOCUMENTS,
    RECEIVE_K1_DELIVEREDSTATUS_DOCUMENTS,
    REQUEST_K1_PARTNERS,
    RECEIVE_K1_PARTNERS,
    CLEAR_K1_PARTNERS,
    UPDATE_DOCUMENTORDER,

    REQUEST_ATTACHMENT_INSTRUCTIONS,
    RECEIVE_ATTACHMENT_INSTRUCTION,
    RECEIVE_ATTACHMENT_INSTRUCTIONS,
    DELETE_ATTACHMENT_INSTRUCTION,

    REQUEST_ADDITIONAL_ESIGN_DOCUMENT,
    RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK,
       
    RECEIVE_INBOX_MESSAGES,
    UPDATE_INBOX_MESSAGE,
    INBOX_MESSAGE_ARRIVAL,
    REQUEST_INBOX_MESSAGE_CLEARALL,
    RESPONSE_INBOX_MESSAGE_CLEARALL,

    // SF
    REQUEST_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES,
    RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES,
    RESET_SIGNATURE_FLOW_REPORT_DOCUMENT_PAGES,

    RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_POPUP_MODAL,

    REQUEST_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN,
    RECEIVE_SIGNATURE_FLOW_REPORT_CUSTOM_COLUMN,

    REQUEST_SIGNATURE_FLOW_REPORT_FILTER,
    RESET_SIGNATURE_FLOW_REPORT_FILTER,
    RECEIVE_SIGNATURE_FLOW_REPORT_FILTER,
    SAVE_SIGNATURE_FLOW_REPORT_FILTER,
    UPDATE_SIGNATURE_FLOW_REPORT_FILTER,
    DELETE_SIGNATURE_FLOW_REPORT_FILTER,

    REQUEST_SIGNATURE_FLOW_SETTINGS,
    RECEIVE_SIGNATURE_FLOW_SETTINGS,
    ERROR_SIGNATURE_FLOW_SETTINGS,

    RECEIVE_SIGNATURE_FLOW_USER_DELEGATION,
    RECEIVE_SIGNATURE_FLOW_USER_DELEGATEE,

    FETCH_SIGNATURE_FLOW_SIGNER_DELEGATION,
    PUT_SIGNATURE_FLOW_SIGNER_DELEGATION,

    REQUEST_SF_DOCUMENT,
    RECEIVE_SF_DOCUMENT_LINK,
    RECEIVE_STORAGE_ACCOUNT_ID,
    RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPES,
    RECEIVE_SIGNATURE_FLOW_DOCUMENT_TYPE,
    UPDATE_SIGNATURE_FLOW_DOCUMENT_TYPES,
    UPDATE_SIGFLOW_DATA,
    RECEIVE_SF_DATA,
    RESET_SF_DATA,
    RESET_SF_SETTINGS_DATA,
    RECEIVE_CONTACTS,
    DELETE_RECOGNIZED_DOCUMENT_SIGNATUE_DATA,
    

    RECEIVE_SIGNATURE_FLOW_REPORT_DOCUMENT_ACCESS,
    RECEIVE_DRAFT_REPORT_ASSIGN_TO,
    RECEIVE_SIGNATURE_FLOW_REPORT_CANCELLED_DESCRIPTION,
    SIGNATURE_FLOW_REPORT_CANCEL_DOCUMENT,
    RECEIVE_SIGNATURE_FLOW_REPORT_ACCESS_CODE,
    GENERATE_SIGNATURE_FLOW_REPORT_ACCESS_CODE,
    RECEIVE_SIGNATURE_FLOW_REPORT_CLIENT_TRACKING,
    RECEIVE_SIGNATURE_FLOW_REPORT_DOWNLOAD_HISTORY,
    RECEIVE_SIGNATURE_FLOW_REPORT_DOWNLOAD,
    RECEIVE_SIGNATURE_FLOW_REPORT_DOWNLOADALL,
    RECEIVE_SIGNATURE_FLOW_REPORT_SIGNERS,
    UNLOCK_DIRECT_DOCUMENT,
    RECEIVE_LOCKED_DIRECT_ACCESS_ACCOUNTS,


    REQUEST_SIGNATURE_FLOW_AUTHENTICATION_QUESTIONS,
    RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTIONS,
    RECEIVE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION,
    DELETE_SIGNATURE_FLOW_AUTHENTICATION_QUESTION,

    REQUEST_CLIENT_AUTHENTICATION_PARAM,
    RECEIVE_CLIENT_AUTHENTICATION_PARAM,

    REQUEST_SIGNATURE_RECIPIENTS,
    RECEIVE_SIGNATURE_RECIPIENTS,
    REQUEST_SIGNING_INFO,
    RECEIVE_SIGNING_INFO,
    ERROR_SIGNING_INFO,
    RECEIVE_SIGNING_INFO_DOCUMENTS,
    RECEIVE_PRIMARY_UTE_LINKED_EMAIL,
    // Mail Merge
    RECEIVE_MAILMERGE_DATA,
    RESET_MAILMERGE_DATA,

    RECEIVE_DELIVERED_RETURN_COLUMN_SETTINGS,

    REQUEST_SF_USER_LIST,
    RECEIVE_SF_USER_LIST,
    ERROR_SF_USER_MANAGEMENT,
    RECEIVE_SSR_USER_LIST,
    RECEIVE_COLUMN_SETTINGS,

    RECEIVE_MFAIPADDRESS_LIST,
    REQUEST_MFAIPADDRESS_LIST,
    ADD_MFAIPADDRESS_STATE,
    UPDATE_MFAIPADDRESS_STATE,
    RECEIVE_MFAUSER_LIST,
    REQUEST_MFAUSER_LIST,
    UPDATE_MFA_SETTINGS,

    REQUEST_ONETIMEPASSWORD,
    RECEIVE_ONETIMEPASSWORD,
    VALIDATE_ONETIMEPASSWORD,

    REQUEST_WATERMARK,
    RECEIVE_WATERMARK,
    UPDATE_WATERMARK,
    DELETE_WATERMARK,
    ADD_WATERMARK,
    RECEIVE_SAMPLE_PDF_OBJECT,
    // My download List(Bulk operation)
    REQUEST_MY_DOWNLOADS_LIST,
    RECEIVE_MY_DOWNLOADS_LIST,

    REQUEST_RECOGNIZED_SIGNATURE_DOCUMENTINFO,
    RECEIVE_RECOGNIZED_SIGNATURE_DOCUMENTINFO,



    REQUEST_SIGNING_INFO_SETTINGS,
    RECEIVE_SIGNING_INFO_SETTINGS,
    ERROR_SIGNING_INFO_SETTINGS,
    UPDATE_SIGNING_INFO_SETTINGS,
    RECEIVE_SIGNING_INFO_REMINDER_SETTINGS,
    RECEIVE_MULTIPLE_SIGNING_INFO_REMINDER_SETTINGS,
    LOADING_SIGNING_INFO_SETTINGS,
    RESET_SIGNING_INFO_SETTINGS,

    ////////////////////////////////// Unlock Recipients Actions //////////////////////////////////
    REQUEST_LOCKED_EMAIL_ADDRESSES,
    RECEIVE_LOCKED_EMAIL_ADDRESSES,
    UNLOCK_LOCKED_EMAIL_ADDRESSES,

    // SSSuite
    RECEIVE_NAVIGATION_MENU,
    RECEIVE_SUITE_URL,
    RECIEVE_MENU_LIST,

    //OfficeLocation
    REQUEST_OFFICE_LOCATION_DROPDOWN,
    RECEIVE_OFFICE_LOCATION_DROPDOWN,

    REQUEST_USER_OFFICE_LOCATION,
    RECEIVE_USER_OFFICE_LOCATION,

    //////////////////////////////////////// Locked Report Actions ////////////////////////////////////////
    REQUEST_LOCKED_REPORT_DOCUMENTS,
    RECEIVE_LOCKED_REPORT_DOCUMENTS,
    RESET_LOCKED_REPORT_DOCUMENTS,

    //////////////////////////////////////// Recycle Report Actions ////////////////////////////////////////
    REQUEST_RECYCLE_REPORT_DOCUMENTS,
    RECEIVE_RECYCLE_REPORT_DOCUMENTS,
    RESET_RECYCLE_SIGNATURE_REPORTS,

    //////////////////////////////////////// Draft Report Actions ////////////////////////////////////////
    REQUEST_DRAFT_REPORT_DOCUMENTS,
    RECEIVE_DRAFT_REPORT_DOCUMENTS,
    RESET_DRAFT_REPORT_DOCUMENTS,
    RECEIVE_DRAFT_DOCUMENTS_LOCKED_STATE,

    //////////////////////////////////////// Archive Report Actions ////////////////////////////////////////
    REQUEST_ARCHIVE_REPORT_DOCUMENTS,
    RECEIVE_ARCHIVE_REPORT_DOCUMENTS,
    RESET_ARCHIVE_REPORT_DOCUMENTS,
    RECEIVE_ARCHIVE_REPORT_DOCUMENT,
    SIGNATURE_ARCHIVE_REPORT_SEND_REMINDER,

    //////////////////////////////////////// Delivered Report Actions ////////////////////////////////////////
    REQUEST_DELIVERED_REPORT_DOCUMENTS,
    RECEIVE_DELIVERED_REPORT_DOCUMENTS,
    RECEIVE_DELIVERED_REPORT_DOCUMENT,
    RESET_DELIVERED_REPORTS,
    SIGNATURE_DELIVERED_REPORT_SEND_REMINDER,
}
