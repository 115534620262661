import { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import { ISectionData } from "@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget";
import { addTask } from "domain-task";
import { Action, Reducer } from "redux";
import { SSSUITE_API_BASE_URL } from "src/utils/AppConstants";
import { AppThunkAction } from "..";
import { actionTypes } from "../ActionTypes";
import { logger } from '../../../routes/LoggedIn';
import { validateError } from "src/SignatureFlow/components/helper/Validations";
import { IMenuSection } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";

export interface ISSSuiteState {
    navigationMenus: ISectionData[];
    suiteUrl: string;
    menuList : IMenuSection[];
    isdataFetched: boolean;
}

interface ReceiveNavigationMenu {
    type: actionTypes.RECEIVE_NAVIGATION_MENU;
    menus: ISectionData[];
}

interface ReceiveSuiteUrl {
    type: actionTypes.RECEIVE_SUITE_URL;
    url: string;
}

interface RecieveMenuList {
    type:actionTypes.RECIEVE_MENU_LIST
    menuList: IMenuSection[];
    isdataFetched: boolean;
}

type KnownAction =  ReceiveNavigationMenu |
                    ReceiveSuiteUrl |
                    RecieveMenuList;

export const actionCreators = {
    getNavigationMenu: (productType: ProductType,callback?: (data: ISectionData[]) => void): AppThunkAction<KnownAction> => (dispatch) => {
        const fetchTask = fetch(`${SSSUITE_API_BASE_URL}/api/menu/widget-menu/${productType}`, { credentials: 'include' })
            .then(response => response.json() as Promise<ISectionData[]>)
            .then((data: ISectionData[]) => {
                dispatch({ type: actionTypes.RECEIVE_NAVIGATION_MENU, menus: data });
                if (callback) {
                    callback(data);
                }
            })
            .catch((error)=>{ 
                logger.trackError(`getNavigationMenu failed with error ${validateError(error)}`);
            })
        addTask(fetchTask);    
                
    },
    getSuiteUrl: (callback?: (data: string) => void): AppThunkAction<KnownAction> => (dispatch) => {
        const fetchTask = fetch(`${SSSUITE_API_BASE_URL}/api/helper/suite-url`, { credentials: 'include' })
            .then(response => response.text() as Promise<string>)
            .then((data: string) => {
                dispatch({ type: actionTypes.RECEIVE_SUITE_URL, url: data });
                if(callback){
                    callback(data);
                }
            })
            .catch((error) =>{
                logger.trackError(`getSuiteUrl failed with error ${validateError(error)}`);
            })
        addTask(fetchTask);
    },
    getSidemenuData: (callback?: (data: IMenuSection[]) => void) : AppThunkAction<KnownAction> => (dispatch) => {
        const fetchTask = fetch(SSSUITE_API_BASE_URL + `api/menu/product-left-menu/${ProductType.SignaturesOneOff}`, {
                credentials: 'include',
                    method: 'GET',
                })
                .then((response) => response.json() as Promise<IMenuSection[]>)
                .then((data) => {
                    dispatch({ type: actionTypes.RECIEVE_MENU_LIST , menuList: data, isdataFetched: true });
                    if(callback){
                        callback(data);
                    }                                    
                })
                .catch(function (error) {
                    logger.trackError(`get Left Menu data failed with error ${validateError(error)}`);
                })

       addTask(fetchTask);
    },
    
}

const unloadedState: ISSSuiteState = {
    navigationMenus: [],
    suiteUrl: '',
    menuList : [],
    isdataFetched: false
}


export const reducer: Reducer<ISSSuiteState> = (state: ISSSuiteState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.RECEIVE_NAVIGATION_MENU: {
            const newState = { ...state };
            newState.navigationMenus = action.menus;
            return newState;
        }
        case actionTypes.RECEIVE_SUITE_URL: {
            const newState = { ...state };
            newState.suiteUrl = action.url;
            return newState;
        }  
        case actionTypes.RECIEVE_MENU_LIST: {
            return({
                ...state, 
                menuList: action.menuList,
                isdataFetched : action.isdataFetched
            })
        }  
    }

    return state || unloadedState;
};
