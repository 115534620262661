import * as React from 'react';
import * as bootbox from 'bootbox';
import { RouteComponentProps } from 'react-router';
import { SortDirections, ReportFilterType } from '../common/Filters';
import {
    ISignatureFlowReportFilter,
    SignatureFlowReportSignatureStatus,
    IMailData,
    ISignerModel,
    ISignatureFlowReportDocument,
    IDocumentAccess,
    IDownloadableDocuments,
    ISignatureFlowReportsDocumentInfo,
    ClientTypes,
    IDocumentEventData,
} from '../../models/SignatureFlowReportState';
import { SignatureArchiveReportTable } from './ArchiveReports/SignatureArchiveReportTable';
import * as UserStore from '../../store/SFUserStore';
import * as ArchiveReportStore from '../../store/ReportsStore/ArchivedReport/ArchivedReportStore';
import * as BulkOperationsStore from '../../store/SFBulkOperationsStore';
import * as UserProfileStore from '../../store/userManagement/UserProfileStore';
import * as UserSettingsStore from '../../store/userManagement/UserSettingStore';
import * as SignatureStore from '../../store/SignatureStore';
import * as ContactStore from '../../store/ContactsStore';
import * as SFStore from '../../store/SFStore';
import * as OneHubStore from '../../store/common/OneHubStore';
import { IUserProfile } from '../../components/navigation/profile/ProfileObjects';
import * as SignatureFlowReportState from '../../models/SignatureFlowReportTableState';
import * as SignatureFlowReportCustomColumnStore from '../../store/ReportsCommon/SignatureFlowReportCustomColumnStore';
import * as SignatureFlowReportFilterStore from '../../store/ReportsCommon/SignatureFlowReportFilterStore';
import * as SignatureFlowReportPopupModelStore from '../../store/ReportsCommon/SignatureFlowReportPopupModelStore';
import * as SignatureFlowSettingsStore from '../../store/SignatureFlowSettingsStore';
import * as SignatureFlowAuthenticationQuestionsStore from '../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import { VenusNotifier } from '../../components/helper/VenusNotifier';
import * as Helper from '../../components/helper/HelperFunctions';
import { SignatureFlowReportsHeader } from './SignatureFlowReportHeader';
import { INameValue } from '../../components/helper/Helper';
import { SignatureFlowESignDetailsModal } from './ActionButtonModals/SignatureFlowESignDetailsModal';
import { SignatureFlowReportDocumentModal } from './ActionButtonModals/SignatureFlowReportDocumentModal';
import { SignatureFlowReportSetAccessModal } from './ActionButtonModals/SignatureFlowReportSetAccessModal';
import { SignatureFlowReportDocumentNoteModal } from './ActionButtonModals/SignatureFlowReportDocumentNoteModal';
import { SignatureFlowReportCancelDocument } from './ActionButtonModals/SignatureFlowReportCancelDocumentModal';
import { ResendAccessLinkModal } from './ActionButtonModals/ResendAccessLinkModal';
import { SignatureFlowReportClientTrackingModal } from './ActionButtonModals/SignatureFlowReportClientTrackingModal';
import { SignatureFlowReportDownloadDocuments } from './ActionButtonModals/SignatureFlowReportDownloadDocumentsModal';
import { ViewAccessCode } from './ActionButtonModals/ViewAccessCodeModal';
import { IOnehubAccessCodeModel } from '../common/OneHubAccessCodeModal';
import { SignedDocumentDownloadHistory } from './ActionButtonModals/SignatureFlowReportSignedDocumentDownloadHistory';
import { SignatureFlowReportRecipientModal } from './ActionButtonModals/SignatureFlowReportRecipientDetailsModal';
import { ReportProblemModal } from '../common/ReportProblemModal';
import * as CompanyStore from '../../store/Company/CompanyStore';
import * as SigningInfoSettingsStore from '../../store/SigningInfoSettingsStore';
import { ReportProblemTypes, IReportProblemDetails } from '../common/ReportProblemModel';
import {
    SignatureFlowReportFilterConstants,
    SignatureFlowReportConstants,
    DeleteSignatureConstants,
    RestoreSignatureReturnsConstants,
    SizePerPageList,
} from '../helper/Constants';
import { HideLoader } from '../../components/helper/Loader';
import { ISigningInfoDictionary } from '../../store/SignatureStore';
import { ISendForSignatureData } from '../../store/SFStore';
import { EditClientInfoModal } from './ActionButtonModals/EditClientInfoModal';
import { IClient, initialLocationMismatchModalState, initialSigningInfo, ISigningInfo } from '../../models/SigningInfo';
import { IContactsState } from '../../store/ContactsStore';
import { logger } from '../../../routes/LoggedIn';
import { getAdjustedReportsPageNumber, getCurrentTaxYear } from '../../components/helper/HelperFunctions';
import { SendReminderModal } from './ActionButtonModals/SendReminderModal';
import { IReminderSettings } from '../../models/SignatureFlowSettings';
import { initialReminderSettings } from '../../models/SigningInfoSettings';
import { ISigningInfoSettingDictionary } from '../../store/SigningInfoSettingsStore';
import { ResourceIdConstants, ScreenIds } from '../helper/RBACConstants';
import { ArchiveReportPaths, CommonPaths, SignatureReportPaths } from '../helper/UrlPaths';
import Pagination from '../common/Pagination';
import { DefaultDownloadInfoType } from '../../models/user/UserSettingsModels';
import { DownloadTypeOption } from './ActionButtonModals/BulkDownloadOptions';
import * as OfficeLocationStore from '../../store/MultiOffice/OfficeLocationStore';
import { ChangeOfficeLocationModal } from './ActionButtonModals/ChangeOfficeLocationModal';
import LocationMismatchModal from '../LocationMismatch/LocationMismatchModal';
import {
    ClientInfoComparisionCode,
    IClientInfoComparisonResultViewModel,
    IClientInfoViewModel,
    initialClientInfoComparisonResultViewModel,
    initialClientInfoMismatchModalState,
    initialClientInfoViewModel,
    setNewClientInfoComparisonResult,
} from 'src/SignatureFlow/models/ClientManagement';
import { isEmpty, trim } from 'lodash';
import {
    addClientInfo,
    CompareClientInfo,
    editClientInfo,
    getClientInfoByClientId,
} from 'src/SignatureFlow/store/ClientManagementAPI';
import ClientInfoMismatchModal from '../ClientManagement/ClientInfoMismatchModal';
import { UnlockRecipientsModal } from './ActionButtonModals/UnlockRecipientsModal';
var htmlencode = require('htmlencode');

// At runtime, Redux will merge together...
type SignatureArchiveReportProps = {
    ArchivedReportDocuments: SignatureFlowReportState.ISignatureFlowReportState; // ... state we've requested from the Redux store
    userStore: UserStore.ISFUserData;
    signatureFlowReportDetails: SignatureFlowReportPopupModelStore.ISignatureFlowReportPopupDictionary;
    signatureFlowReportFilter: SignatureFlowReportFilterStore.IFilterState;
    reportCustomColumns: SignatureFlowReportCustomColumnStore.ISignatureFlowReportCustomColumnState;
    companyData: CompanyStore.ICompanyData;
    profile: IUserProfile;
    settings: UserSettingsStore.UserSettings;
    signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData;
    signingInfoData: ISigningInfoDictionary;
    contactsStore: IContactsState;
    sfStore: ISendForSignatureData;
    userOfficeLocationState: OfficeLocationStore.IUserOfficeLocationState;
    officeLocationState: OfficeLocationStore.IOfficeLocationState;
    signingInfoSettingsData: ISigningInfoSettingDictionary;
    pageTitle: string;
    authenticationQuestionsData: SignatureFlowAuthenticationQuestionsStore.IAuthenticationQuestionsData;
    oneHubData: OneHubStore.IOneHubState;
} & typeof ArchiveReportStore.actionCreators & // ... plus action creators we've requested
    typeof SignatureFlowReportCustomColumnStore.actionCreators &
    typeof UserStore.actionCreators &
    typeof SignatureFlowReportFilterStore.actionCreators &
    typeof SignatureFlowReportPopupModelStore.actionCreators &
    typeof UserProfileStore.actionCreators &
    typeof UserSettingsStore.actionCreators &
    typeof SignatureFlowSettingsStore.actionCreators &
    typeof BulkOperationsStore.actionCreators &
    typeof SignatureStore.actionCreators &
    typeof SFStore.actionCreators &
    typeof ContactStore.actionCreators &
    typeof SigningInfoSettingsStore.actionCreators &
    typeof SignatureFlowAuthenticationQuestionsStore.actionCreators &
    typeof OneHubStore.actionCreators &
    typeof OfficeLocationStore.actionCreators &
    RouteComponentProps<{ page: string }>; // ... plus incoming routing parameters

let moment = require('moment');
const pageSize: number = 10;
const NO_INDEX = -1;

const warningMsg: INameValue = {
    cancelDocumentConfirmation: 'Are you sure you want to cancel this document?',
};

export class SignatureArchiveReports extends React.Component<
    SignatureArchiveReportProps,
    SignatureFlowReportState.ISignatureFlowReportPageState
> {
    constructor(props: SignatureArchiveReportProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter: {
                name: '',
                searchText: '',
                sort: {
                    column: '',
                    direction: SortDirections.None,
                },
                fields: {},
                isDefault: 0,
                filterType: ReportFilterType.None,
                isDefaultFilter: false,
                isMasterFilter: false,
            },
            selectedRows: [],
            searchString: '',
            sortName: '',
            sortOrder: 'desc',
            saveFilterShow: false,
            filterSenderName: '',
            filterDocumentName: '',
            filterDocumentType: '',
            filterDocumentNote: '',
            filterDeliveredOn: undefined,
            filterArchivedOn: undefined,
            filterLastReminderOn: undefined,
            filterSigners: '',
            filterSignatureStatus: '',
            filterExpiration: undefined,
            filterTitle: '',
            filterDownloadStatus: '',
            filterLoggedUser: '',
            filterTaxYear: getCurrentTaxYear(),
            filterRecipientName: '',
            filterClientId: '',
            filterOfficeLocation: '',
            selectedColumnValue: 0,
            documentNoteMessage: '',
            showLoader: false,
            signatureStatusList: [],
            selectedStatus: 0,
            setAccessHeaderTextDisplay: false,
            zipFileDownloadName: '',
            downloadableDocuments: [],
            customColumns: [],
            tempSigninfo: initialSigningInfo,

            //SendRemider states
            sendReminderState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //Resend Access Link states
            resendAccessLinkState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Client Tracking states
            clientTrackingState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Report a Problem states
            reportProblem: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Access Code states
            accessCodeState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },
            onehubAccessCodeState: {
                model: undefined,
            },

            //Download Return State
            downloadReturnState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //Signer Details State
            signerDetailsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Document Details State
            documentDetailsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Client View State
            clientViewState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Set Access State
            setAccessState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            // Document Note State
            documentNoteState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Delete Action states
            deleteReturnsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Archive Action states
            archiveReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //Cancel Document State
            cancelDocumentState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Download History State
            downloadHistoryState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Recipient Details State
            recipientDetailsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Edit ClientInfo State
            editClientInfoState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Download Type Option State
            DownloadTypeOptionState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //Change Office Location State
            changeOfficeLocationState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //Unlock UTE recipients state
            unlockRecipientsState: {
                showModal: false,
                isLoading: false,
                lockedEmailAddresses: [],
                lockedDirectAccessEmailAddresses: [],
                id: 0,
            },

            isBulkSelectionEnabled: false,
            deSelectedRows: [],
            showBulkSelectionMessage: false,
            totalRecordsCount: 0,

            locationMismatchModalState: initialLocationMismatchModalState,
            clientInfo: initialClientInfoViewModel,
            clientInfoComparisonResult: initialClientInfoComparisonResultViewModel,
            clientInfoMismatchModalState: initialClientInfoMismatchModalState,
            compareClientInfoProgress: false,

            selectedDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            defaultDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            selectedDefaultDownloadType: false,
            clientHubEvents: [],
        };

        this.onPageReload = this.onPageReload.bind(this);

        //Grid Operation Handlers
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onExportToExcel = this.onExportToExcel.bind(this);

        //filter operation handlers
        this.onFilterChange = this.onFilterChange.bind(this);
        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.onFilterDelete = this.onFilterDelete.bind(this);
        this.onFilterNameChange = this.onFilterNameChange.bind(this);

        this.onSetAccessOpen = this.onSetAccessOpen.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.onSetAccessCancel = this.onSetAccessCancel.bind(this);
        this.onGenerateOnehubAccessCode = this.onGenerateOnehubAccessCode.bind(this);

        this.onSendReminder = this.onSendReminder.bind(this);

        this.onResendAccessLinkOpen = this.onResendAccessLinkOpen.bind(this);
        this.onResendAccessLink = this.onResendAccessLink.bind(this);
        this.onResendAccessLinkCancel = this.onResendAccessLinkCancel.bind(this);

        this.onClientTrackingOpen = this.onClientTrackingOpen.bind(this);
        this.onClientTrackingCancel = this.onClientTrackingCancel.bind(this);

        this.onViewAccessCodeOpen = this.onViewAccessCodeOpen.bind(this);
        this.onViewAccessCodeCancel = this.onViewAccessCodeCancel.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);

        this.onReportProblemOpen = this.onReportProblemOpen.bind(this);
        this.onReportProblemCancel = this.onReportProblemCancel.bind(this);
        this.onReportProblemSave = this.onReportProblemSave.bind(this);

        this.onSignersDetailOpen = this.onSignersDetailOpen.bind(this);
        this.onSignersDetailCancel = this.onSignersDetailCancel.bind(this);

        this.onDocumentDetailsOpen = this.onDocumentDetailsOpen.bind(this);
        this.onDocumentDetailsCancel = this.onDocumentDetailsCancel.bind(this);

        this.onDocumentNoteOpen = this.onDocumentNoteOpen.bind(this);
        this.onDocumentNoteChange = this.onDocumentNoteChange.bind(this);
        this.onUpdateDocumentNote = this.onUpdateDocumentNote.bind(this);
        this.deleteDocumentNoteMessage = this.deleteDocumentNoteMessage.bind(this);
        this.onDocumentNoteCancel = this.onDocumentNoteCancel.bind(this);

        this.onDownloadReturnOpen = this.onDownloadReturnOpen.bind(this);
        this.onDownloadIndividualOpen = this.onDownloadIndividualOpen.bind(this);
        this.onDownloadReturnCancel = this.onDownloadReturnCancel.bind(this);
        this.onDownloadReturnAll = this.onDownloadReturnAll.bind(this);
        this.onDownloadIndividualFile = this.onDownloadIndividualFile.bind(this);
        this.onDownloadOptionChange = this.onDownloadOptionChange.bind(this);
        this.onDownloadSubmit = this.onDownloadSubmit.bind(this);
        this.onDownloadTypeCancel = this.onDownloadTypeCancel.bind(this);

        this.onDeleteDocumentsOpen = this.onDeleteDocumentsOpen.bind(this);
        this.onRestoreDocumentsOpen = this.onRestoreDocumentsOpen.bind(this);

        this.onDeleteSelectedReturns = this.onDeleteSelectedReturns.bind(this);
        this.onRestoreSelectedReturns = this.onRestoreSelectedReturns.bind(this);

        this.onCancelDocumentConfirmation = this.onCancelDocumentConfirmation.bind(this);
        this.onCancelDocumentOpen = this.onCancelDocumentOpen.bind(this);
        this.onSaveCancelDocumentDescription = this.onSaveCancelDocumentDescription.bind(this);
        this.onCancelDocumentCancel = this.onCancelDocumentCancel.bind(this);

        this.onDeleteDocumentConfirmation = this.onDeleteDocumentConfirmation.bind(this);

        this.onDownloadHistoryOpen = this.onDownloadHistoryOpen.bind(this);
        this.onDownloadHistoryCancel = this.onDownloadHistoryCancel.bind(this);

        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.buildQueryForExportToExcel = this.buildQueryForExportToExcel.bind(this);

        this.hasAllSignersSigned = this.hasAllSignersSigned.bind(this);
        this.onChangeOfficeLocationOpen = this.onChangeOfficeLocationOpen.bind(this);
        this.onUnlockUTELockedRecipientOpen = this.onUnlockUTELockedRecipientOpen.bind(this);
        this.onUnlockRecipientsModalClose = this.onUnlockRecipientsModalClose.bind(this);
        this.onUnlockUTEAccount = this.onUnlockUTEAccount.bind(this);
        this.onUnlockDirectDocument = this.onUnlockDirectDocument.bind(this);
    }

    UNSAFE_componentWillMount() {
        logger.trackPageView('Signature Archive Report Page');
        if (!this.props.ArchivedReportDocuments.isLoading) {
            this.props.requestSignatureArchiveDocuments(
                this.buildQuery(this.state.page, pageSize),
                true,
                this.callBack
            );
        }
        this.props.requestCompanySignatureSettings();
        this.props.requestSignatureArchiveReportFilter();
        this.props.requestAllSFUsers(true);
        this.props.requestAuthenticationQuestions();
    }

    UNSAFE_componentWillReceiveProps(nextProps: SignatureArchiveReportProps) {
        if (nextProps.ArchivedReportDocuments.totalRowCount !== this.state.totalRecordsCount) {
            if (!nextProps.ArchivedReportDocuments.isLoading) {
                this.setState({
                    totalRecordsCount: nextProps.ArchivedReportDocuments.totalRowCount,
                });
            }
        }
        if (nextProps.reportCustomColumns.ArchivedCustomColumn.length > 0) {
            this.setState({
                customColumns: nextProps.reportCustomColumns.ArchivedCustomColumn,
            });
        }

        nextProps.settings &&
            nextProps.settings.signatureSettings &&
            nextProps.settings.signatureSettings.defaultSettings &&
            nextProps.settings.signatureSettings.defaultSettings.defaultDownloadInfo &&
            this.setState({
                defaultDownloadType:
                    nextProps.settings.signatureSettings.defaultSettings.defaultDownloadInfo.downloadType,
            });
    }

    buildQuery(page: number, pageSize: number) {
        return (
            '?pageNo=' +
            page +
            '&pageSize=' +
            pageSize +
            '&sortBy=' +
            this.state.sortName +
            '&sortOrder=' +
            this.state.sortOrder +
            '&searchString=' +
            this.state.searchString +
            '&filterSenderName=' +
            encodeURIComponent(this.state.filterSenderName) +
            '&filterDocumentName=' +
            encodeURIComponent(this.state.filterDocumentName) +
            '&filterDocumentType=' +
            this.state.filterDocumentType +
            '&filterDocumentNote=' +
            encodeURIComponent(this.state.filterDocumentNote) +
            '&filterDownloadStatus=' +
            this.state.filterDownloadStatus +
            '&filterSignatureStatus=' +
            (this.state.filterSignatureStatus === '-1' ? '' : this.state.filterSignatureStatus) +
            '&filterDeliveredOn=' +
            (this.state.filterDeliveredOn || '') +
            '&filterArchivedOn=' +
            (this.state.filterArchivedOn || '') +
            '&filterExpiration=' +
            (this.state.filterExpiration || '') +
            '&filterSigners=' +
            this.state.filterSigners +
            '&filterTitle=' +
            encodeURIComponent(this.state.filterTitle) +
            '&filterLoggedUser=' +
            this.state.filterLoggedUser +
            '&filterTaxYear=' +
            (this.state.filterTaxYear === '-1' ? '' : this.state.filterTaxYear) +
            '&filterRecipientName=' +
            encodeURIComponent(this.state.filterRecipientName) +
            '&filterClientId=' +
            encodeURIComponent(this.state.filterClientId) +
            '&filterLastReminderOn=' +
            (this.state.filterLastReminderOn || '') +
            '&filterOfficeLocation=' +
            (this.state.filterOfficeLocation === '-1' ? '' : this.state.filterOfficeLocation)
        );
    }

    buildQueryForExportToExcel(page: number, pageSize: number) {
        return (
            '?pageNo=' +
            page +
            '&pageSize=' +
            pageSize +
            '&sortBy=' +
            this.state.sortName +
            '&sortOrder=' +
            this.state.sortOrder +
            '&searchString=' +
            this.state.searchString +
            '&filterSenderName=' +
            encodeURIComponent(this.state.filterSenderName) +
            '&filterDocumentName=' +
            encodeURIComponent(this.state.filterDocumentName) +
            '&filterDocumentType=' +
            this.state.filterDocumentType +
            '&filterDocumentNote=' +
            encodeURIComponent(this.state.filterDocumentNote) +
            '&filterDownloadStatus=' +
            this.state.filterDownloadStatus +
            '&filterSignatureStatus=' +
            (this.state.filterSignatureStatus === '-1' ? '' : this.state.filterSignatureStatus) +
            '&filterDeliveredOn=' +
            (this.state.filterDeliveredOn || '') +
            '&filterArchivedOn=' +
            (this.state.filterArchivedOn || '') +
            '&filterExpiration=' +
            (this.state.filterExpiration || '') +
            '&filterSigners=' +
            this.state.filterSigners +
            '&filterTitle=' +
            encodeURIComponent(this.state.filterTitle) +
            '&filterLoggedUser=' +
            this.state.filterLoggedUser +
            '&filterTaxYear=' +
            (this.state.filterTaxYear === '-1' ? '' : this.state.filterTaxYear) +
            '&filterRecipientName=' +
            encodeURIComponent(this.state.filterRecipientName) +
            '&filterClientId=' +
            encodeURIComponent(this.state.filterClientId) +
            '&filterLastReminderOn=' +
            (this.state.filterLastReminderOn || '') +
            '&filterOfficeLocation=' +
            (this.state.filterOfficeLocation === '-1' ? '' : this.state.filterOfficeLocation)
        );
    }

    loadSignatureFlowReports() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestSignatureArchiveDocuments(queryString, false);
    }

    fetchSignatureFlowReports(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page }, () => {
            this.props.requestSignatureArchiveDocuments(queryString), false;
        });
    }

    callBack = () => {
        this.props.requestSignatureArchiveReportCustomColumn();
        this.props.requestSignatureFlowReportCustomColumn();
    };
    //======================Grid Operation Starts here===============================

    private onRowSelect(row: any, isSelected: any, e: any) {
        let newList = [...this.state.selectedRows];
        if (
            e.target.tagName !== 'BUTTON' &&
            e.target.tagName !== 'I' &&
            e.target.tagName !== 'SPAN' &&
            e.target.tagName !== 'A' &&
            e.target.tagName !== 'DIV'
        ) {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    private onSelectAll(isSelected: boolean, rows: any[], e: any) {
        let selectedRows: number[] = [];
        if (isSelected) {
            rows.forEach((value: any, index: number) => {
                selectedRows.push(value.rowIndex);
            });
        }
        this.setState({ selectedRows: selectedRows });
    }

    private onPageChange(page: number, pageSize: number) {
        this.setState(
            {
                page: page,
                pageSize: pageSize,
                selectedRows: [],
            },
            this.loadSignatureFlowReports
        );
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        this.clearSelection();
        let temp: ISignatureFlowReportFilter = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == 'asc' ? SortDirections.Ascending : SortDirections.Descending;
        this.setState(
            {
                filter: temp,
                sortName: sortName,
                sortOrder: sortOrder == 'asc' ? 'asc' : 'desc',
            },
            () => this.fetchSignatureFlowReports(1, this.state.pageSize)
        );
    }

    private onSearchChange(searchString: string) {
        let temp: ISignatureFlowReportFilter = { ...this.state.filter };
        temp.searchText = searchString;
        this.setState(
            {
                searchString: searchString,
                filter: temp,
            },
            () => this.fetchSignatureFlowReports(1, this.state.pageSize)
        );
    }

    private onPageReload() {
        let query = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestSignatureArchiveDocuments(query, true);
        this.setState({ selectedRows: [] });
    }

    //======================Grid Operation Ends here=================================
    private selectedDocumentIds() {
        let documentsDetails: { id: number; recipientName: string }[] = [];
        this.state.selectedRows.forEach(row => {
            let selectedDocument = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                documentsDetails.push({
                    id: selectedDocument.signingId,
                    recipientName: selectedDocument.recipientName,
                });
            }
        });
        return documentsDetails;
    }

    private selectedDocuments() {
        let docs: any[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }
    private onPopupOpen(rowIndex: number) {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            return [this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId];
        }
        if (this.state.selectedRows.length == 0) {
            return [];
        }
        return this.selectedDocumentIds().map(x => x.id);
    }

    private getStoreItem(index: number) {
        let id = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[index].signingId;
        return this.props.signatureFlowReportDetails[id].signatureReport;
    }

    private selectedDocumentsSignersInfo() {
        let sendReminderData: IMailData[] = [];

        this.state.selectedRows.map((row, i) => {
            let selectedDocument = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[row];
            if (selectedDocument) {
                let mailData: IMailData = {
                    signers: [],
                    documents: [],
                    deliveredUser: { email: '', firstName: '', lastName: '', userId: 0 },
                    title: '',
                };
                let nextSigners = selectedDocument.signerInfo
                    .filter(
                        x =>
                            x.isSigned == 0 &&
                            (x.order > 0 ||
                                (x.order == 0 &&
                                    (x.signerType == ClientTypes.Sender || x.signerType == ClientTypes.Delegatee) &&
                                    x.clientId != 0))
                    )
                    .sort((a: ISignerModel, b: ISignerModel) => {
                        if (a.order < b.order) return -1;
                        else return 1;
                    });
                if (nextSigners.length == 0) {
                    return;
                }
                if (
                    selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.Cancelled ||
                    selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.Declined ||
                    selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.ESigned ||
                    selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.SignatureStampingFailed ||
                    selectedDocument.signatureStatus == SignatureFlowReportSignatureStatus.DeliveryFailed
                ) {
                    return;
                }
                let currentOrder = nextSigners[0].order;
                nextSigners
                    .filter(x => x.order == currentOrder)
                    .forEach(signer => {
                        mailData.signers.push(this.checkIfSignerHasDelegatee(signer, selectedDocument));
                    });
                selectedDocument.documentInfo.forEach((document, index) => {
                    mailData.documents.push(document);
                });
                mailData.deliveredUser = selectedDocument.deliveredUserDetails;
                mailData.title = selectedDocument.title;
                sendReminderData.push(mailData);
            }
        });
        return sendReminderData;
    }

    private checkIfSignerHasDelegatee = (
        signer: ISignerModel,
        document: ISignatureFlowReportDocument
    ): ISignerModel => {
        if (signer.delegateeId && signer.delegateeId > 0) {
            const delegatee = document.signerInfo.filter(x => x.clientId == signer.delegateeId)[0];
            return delegatee;
        }
        return signer;
    };

    private onExportToExcel(onExportToExcelComplete: () => void) {
        let queryString = this.buildQueryForExportToExcel(this.state.page, this.state.pageSize);
        this.props.exportSignatureArchiveReportsToExcel(queryString, onExportToExcelComplete);
    }

    private hasAllSignersSigned(selectedReportSigners: ISignerModel[]) {
        let isSigned: boolean = selectedReportSigners
            .filter(signer => signer.order >= 0 && signer.signerType != ClientTypes.CC)
            .every(signer => signer.isSigned == 1);
        return isSigned;
    }

    //======================Filter Operation Starts here=================================

    private applyDefaultFilter(filter: ISignatureFlowReportFilter) {
        this.setState({ filter: filter }, () => this.fetchSignatureFlowReports(1, this.state.pageSize));
    }

    private onFilterChange(dataField: any) {
        let newState: any = {
            filterSenderName: '',
            filterDeliveredOn: undefined,
            filterArchivedOn: undefined,
            filterExpiration: undefined,
            filterSigners: '',
            filterDownloadStatus: '',
            filterSignatureStatus: '',
            filterDocumentName: '',
            filterDocumentType: '',
            filterDocumentNote: '',
            filterTitle: '',
            filterLoggedUser: '',
            filterRecipientName: '',
            filterClientId: '',
            filterLastReminderOn: undefined,
            filterOfficeLocation: '',
        } as any;
        let filterStatus: any;
        let filterTaxYear: any;
        var dict: { [columnName: string]: string } = {};
        for (let field of Object.keys(dataField)) {
            dict[field.valueOf().toString()] = dataField[field.valueOf()].value
                ? dataField[field.valueOf()].value
                : dataField[field.valueOf()];
            switch (field) {
                case 'deliveredBy':
                    newState.filterSenderName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'signatureStatus':
                    let tempField = dataField[field];
                    if (tempField.value !== undefined) {
                        newState.filterSignatureStatus = tempField.value;
                    } else {
                        newState.filterSignatureStatus = tempField;
                    }
                    break;
                case 'officeLocation':
                    let tempLocation = dataField[field];
                    if (tempLocation.value !== undefined) {
                        newState.filterOfficeLocation = tempLocation.value;
                    } else {
                        newState.filterOfficeLocation = tempLocation;
                    }
                    break;
                case 'deliveredOn':
                    newState.filterDeliveredOn = Helper.GetNumberOfDays(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    );
                    break;
                case 'documentStatusChangedOn':
                    newState.filterArchivedOn = Helper.GetNumberOfDays(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    );
                    break;
                case 'signers':
                    newState.filterSigners = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentNote':
                    newState.filterDocumentNote = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'expiration':
                    newState.filterExpiration = moment(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    ).format('MM/DD/YYYY');
                    break;
                case 'downloadStatus':
                    newState.filterDownloadStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentName':
                    newState.filterDocumentName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentType':
                    newState.filterDocumentType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'title':
                    newState.filterTitle = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'loggedUser':
                    newState.filterLoggedUser = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxYear':
                    let tempYear = dataField[field];
                    if (tempYear.value !== undefined) {
                        newState.filterTaxYear = tempYear.value;
                    } else {
                        newState.filterTaxYear = tempYear;
                    }
                    break;
                case 'recipientName':
                    newState.filterRecipientName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'lastReminderOn':
                    newState.filterLastReminderOn = moment(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    ).format('MM/DD/YYYY');
            }
        }
        let tempfilter: ISignatureFlowReportFilter = { ...this.state.filter };
        tempfilter.fields = dict;
        if (this.isFilterChanged(newState, filterStatus, filterTaxYear)) {
            this.clearSelection();
            this.setState({ ...newState, filter: tempfilter, page: 1 }, () => {
                let signatureArchiveReportTable: any = this.refs.SignatureArchiveReportTable;
                if (!signatureArchiveReportTable.isAppliedFilter && !this.props.ArchivedReportDocuments.isLoading)
                    this.props.requestSignatureArchiveDocuments(this.buildQuery(1, this.state.pageSize), true);
            });
        }
    }

    private clearSelection = () => {
        this.setState({ selectedRows: [] });
    };
    private isFilterChanged(
        newState: SignatureFlowReportState.ISignatureFlowReportPageState,
        filterStatus: any,
        filterTaxYear: any
    ): boolean {
        return (
            newState.filterSenderName !== this.state.filterSenderName ||
            newState.filterSignatureStatus !== this.state.filterSignatureStatus ||
            newState.filterDownloadStatus !== this.state.filterDownloadStatus ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterArchivedOn !== this.state.filterArchivedOn ||
            newState.filterExpiration !== this.state.filterExpiration ||
            newState.filterDocumentName !== this.state.filterDocumentName ||
            newState.filterDocumentType !== this.state.filterDocumentType ||
            newState.filterSigners !== this.state.filterSigners ||
            newState.filterDocumentNote != this.state.filterDocumentNote ||
            newState.filterTitle != this.state.filterTitle ||
            newState.filterLoggedUser != this.state.filterLoggedUser ||
            filterTaxYear != this.state.filterTaxYear ||
            newState.filterRecipientName != this.state.filterRecipientName ||
            newState.filterClientId != this.state.filterClientId ||
            newState.filterLastReminderOn != this.state.filterLastReminderOn ||
            newState.filterOfficeLocation != this.state.filterOfficeLocation
        );
    }

    onFilterSave(onApplyFilter: (filter: ISignatureFlowReportFilter) => void) {
        if (this.state.filter.name.trim() != '') {
            let filter = this.props.signatureFlowReportFilter.archivedReportsFilter.find(
                x => x.name == this.state.filter.name
            );
            if (filter) {
                VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterNameAlreadyExists, null);
            } else {
                this.props.saveSignatureArchiveReportFilter(this.state.filter.name, this.state.filter, () => {
                    onApplyFilter(this.state.filter);
                    this.onSaveFilterHide();
                });
            }
        } else {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    private onFilterNameChange(event: any) {
        let temp: ISignatureFlowReportFilter = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp,
        });
    }

    clearFilters = () => {
        this.setState({ selectedRows: [] }, this.onPageReload);
        let signatureArchiveReportTable: any = this.refs.SignatureArchiveReportTable;
        if (signatureArchiveReportTable.refs && signatureArchiveReportTable.refs.Filters) {
            signatureArchiveReportTable.refs.Filters.setState({ activeFilter: '', appliedFilters: [] });
        }
    };

    onFilterUpdate(name: string) {
        let filter = this.props.signatureFlowReportFilter.archivedReportsFilter.find(x => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(SignatureFlowReportFilterConstants.OtherMessage.FilterDoesNotExists, null);
        } else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefault = this.state.filter.isDefault;
            this.props.updateSignatureArchiveReportFilter(name, filter, undefined, true);
            this.onSaveFilterHide();
        }
    }

    private onSetDefaultFilter = (name: string) => {
        let oldDefaultFilter = this.props.signatureFlowReportFilter.archivedReportsFilter.find(x => x.isDefault == 1);
        let filter: ISignatureFlowReportFilter[] = [];
        if (oldDefaultFilter != null && oldDefaultFilter != undefined && oldDefaultFilter.name != name) {
            oldDefaultFilter.isDefault = 0;
            this.props.updateSignatureArchiveReportFilter(oldDefaultFilter.name, oldDefaultFilter, undefined, false);
        }

        filter = this.props.signatureFlowReportFilter.archivedReportsFilter.filter(x => x.name == name);
        if (filter) {
            filter[0].isDefault = 1;
            this.props.updateSignatureArchiveReportFilter(name, filter[0], undefined, true);
        }
    };

    private onRemoveDefaultFilter = (name: string) => {
        let filter = this.props.signatureFlowReportFilter.archivedReportsFilter.find(x => x.name == name);
        if (filter) {
            filter.isDefault = 0;
            this.props.updateSignatureArchiveReportFilter(name, filter, undefined, true);
        }
        let signatureArchiveReportTable: any = this.refs.SignatureArchiveReportTable;
        if (signatureArchiveReportTable && signatureArchiveReportTable.refs && signatureArchiveReportTable.refs.Filters)
            signatureArchiveReportTable.refs.Filters.setState({ activeFilter: '', appliedFilters: [] });
        this.onPageReload;
    };

    private onSaveFilterShow = () => {
        this.setState({
            saveFilterShow: true,
        });
    };

    private onSaveFilterHide = () => {
        this.setState({
            saveFilterShow: false,
        });
    };

    private onFilterDelete(filterName: string) {
        this.props.removeSignatureArchiveReportFilter(filterName);
    }

    //======================Filter Operation Ends here=================================

    //======================Signer Details State=======================================

    private onSignersDetailOpen(rowIndex: number = NO_INDEX) {
        this.setState({
            signerDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
    }

    private onSignersDetailCancel() {
        this.setState({
            signerDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //======================Signer Details Ends Here=======================================

    //======================Document Details State=======================================

    private onDocumentDetailsOpen(rowIndex: number = NO_INDEX) {
        this.setState({
            documentDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
    }

    private onDocumentDetailsCancel() {
        this.setState({
            documentDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //======================Document Details Ends Here=======================================

    //======================SetAccess State========================================

    private onSetAccessOpen(rowIndex: number = NO_INDEX, headerTextShow: boolean) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning('Please select document(s) to set access for other users!', null);
            return;
        }
        this.props.requestAllSFUsers(false);
        this.props.requestSignatureReportAccess(ids, ArchiveReportPaths.SetAccess);
        let selectedReports = ids.map((id, i) => {
            return this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents.filter(
                x => x.signingId == id
            )[0];
        });
        this.setState({
            setAccessState: {
                show: true,
                selected: selectedReports,
                saveCheckIds: undefined,
                remove: false,
            },
            setAccessHeaderTextDisplay: headerTextShow,
        });
        logger.trackTrace('onSetAccessOpen with ids' + JSON.stringify(ids));
    }

    private onSetAccessSave(userIds: number[]) {
        let ids = this.selectedDocumentIds().map(x => x.id);
        if (ids.length == 0 && this.state.setAccessState.selected) {
            this.state.setAccessState.selected.map((reports, i) => {
                ids.push(reports.signingId);
            });
        }
        let accessMaps: IDocumentAccess[] = [];
        ids.forEach((id, i) => {
            accessMaps.push({
                signingId: id,
                userIds: userIds,
            } as IDocumentAccess);
        });
        this.props.setAccessToSignatureReports(accessMaps, ArchiveReportPaths.SetAccess);

        this.setState(
            {
                setAccessState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: ids,
                    remove: false,
                },
                selectedRows: [],
            },
            this.onPageReload
        );
    }

    private onSetAccessCancel() {
        this.setState({
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
        });
    }

    //====================SetAccess Ends Here=======================================

    //====================Resend Access Link Starts Here===============================

    private onResendAccessLinkOpen(rowIndex: number = NO_INDEX) {
        this.setState({
            resendAccessLinkState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
    }

    private onResendAccessLink(signer: ISignerModel, callBack: () => void) {
        const index = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents.findIndex(
            x => x.signingId == signer.signingId
        );
        const id = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[index].signingId;
        const document = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[index];
        let mailData: IMailData = {
            signers: [],
            documents: [],
            deliveredUser: { email: '', firstName: '', lastName: '', userId: 0 },
            title: '',
        };
        mailData.signers.push(this.checkIfSignerHasDelegatee(signer, document));
        this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[index].documentInfo.map(
            (document, index) => {
                mailData.documents.push(document);
            }
        );
        mailData.deliveredUser =
            this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[index].deliveredUserDetails;
        mailData.title = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[index].title;
        this.props.resendAccessLink(mailData, ArchiveReportPaths.ResendAccesslink, callBack);
        logger.trackTrace('onResendAccessLink with signer' + JSON.stringify(signer));
    }

    private onResendAccessLinkCancel() {
        this.setState({
            resendAccessLinkState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }
    //====================Resend Access Link Ends Here===============================

    private onDeleteDocumentsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning('Please select document(s) to delete!', null);
            return;
        }

        bootbox
            .confirm({
                title: 'Delete Document(s)',
                message: DeleteSignatureConstants.ConfirmDeletion,
                buttons: {
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-white modal-footer-button-cancel',
                    },
                    confirm: {
                        label: 'Confirm',
                        className: 'btn-info modal-footer-button-save',
                    },
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.onDeleteSelectedReturns(ids);
                        logger.trackTrace('onDeleteSelectedReturns with ids' + JSON.stringify(ids));
                    }
                },
            })
            .find('.modal-dialog')
            .addClass('modal-dialog-centered');
    }

    private onRestoreDocumentsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(RestoreSignatureReturnsConstants.PleaseSelectDocumentToRestore, null);
            return;
        }

        bootbox
            .confirm({
                title: 'Restore Document(s)',
                message: RestoreSignatureReturnsConstants.documentRestoreConfirmation,
                buttons: {
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-white modal-footer-button-cancel',
                    },
                    confirm: {
                        label: 'Yes',
                        className: 'btn-info modal-footer-button-save',
                    },
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.onRestoreSelectedReturns(ids);
                    }
                },
            })
            .find('.modal-dialog')
            .addClass('modal-dialog-centered');
    }

    private onDeleteSelectedReturns(ids: number[]) {
        this.props.deleteSignatureDocument(ids, ArchiveReportPaths.Delete, () => {
            this.setState({
                page: getAdjustedReportsPageNumber(
                    this.props.ArchivedReportDocuments.signatureFlowReportTableModel.count,
                    ids.length,
                    this.state.page,
                    this.state.pageSize
                ),
            });
            this.clearFilters();
        });
    }
    private onRestoreSelectedReturns(ids: number[]) {
        this.props.RestoreSignatureDocument(ids, () => {
            this.setState({
                page: getAdjustedReportsPageNumber(
                    this.props.ArchivedReportDocuments.signatureFlowReportTableModel.count,
                    ids.length,
                    this.state.page,
                    this.state.pageSize
                ),
            });
            this.clearFilters();
        });
    }

    //====================Download Returns Starts Here===============================

    private getDisplayNameForDloadSignatureDocs(
        report: ISignatureFlowReportDocument,
        document: ISignatureFlowReportsDocumentInfo,
        haveAllSignersSigned: boolean
    ) {
        return (
            Helper.getSignerDetail(report) +
            ' ' +
            Helper.addSignedToDocName(document.documentName, haveAllSignersSigned, report.signatureStatus)
        );
    }

    private validateSignedDocumentsDload(selectedReports: ISignatureFlowReportDocument[]) {
        for (let i = 0; i < selectedReports.length; i++) {
            if (!this.hasAllSignersSigned(selectedReports[i].signerInfo)) return false;
        }
        return true;
    }

    private getDownloadSizeInBytes(size: string) {
        var sizeArray = size.split(' ');
        var intSize = parseInt(sizeArray[0]);
        if (sizeArray[1] == 'KB') {
            return intSize * 1024;
        } else if (sizeArray[1] == 'MB') {
            return intSize * 1024 * 1024;
        } else if (sizeArray[1] == 'GB') {
            return intSize * 1024 * 1024 * 1024;
        }
    }

    private onDownloadIndividualOpen(rowIndex: number = NO_INDEX) {
        this.onDownloadReturnOpen(rowIndex, true);
    }

    private onDownloadReturnOpen(rowIndex: number = NO_INDEX, isIndividual: boolean = false) {
        let ids = this.onPopupOpen(rowIndex);
        let downloadableDocuments: IDownloadableDocuments[] = [];
        let isAllSignersSigned: boolean = false;
        let zipFileName = Helper.getZipFileNameForMultipleDload();
        let zipSize = 0;
        let selectedReports = ids.map((id, i) => {
            return this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents.filter(
                x => x.signingId == id
            )[0];
        });
        if (rowIndex == NO_INDEX) {
            if (!this.validateSignedDocumentsDload(selectedReports)) {
                VenusNotifier.Error(SignatureFlowReportConstants.DownloadDocument.DocumentNotSignedError, null);
                return;
            }
        }

        selectedReports.map((report, index) => {
            let signingGuid = report.signingInfoGuid;
            let id = report.signingId;
            let storageAccountId = 0;
            isAllSignersSigned = this.hasAllSignersSigned(report.signerInfo);
            report.documentInfo.map((document, index) => {
                let downloadableFileData: IDownloadableDocuments = {
                    signingId: 0,
                    documentGuid: '',
                    documentId: 0,
                    fileName: '',
                    size: 0,
                    signingGuid: '',
                    isSigningCompleted: false,
                    fileDownloadName: '',
                    storageAccountId: 0
                };
                downloadableFileData.signingGuid = signingGuid;
                downloadableFileData.signingId = id;
                downloadableFileData.documentGuid = document.documentGuid;
                downloadableFileData.fileName = document.documentName;
                downloadableFileData.documentId = document.documentId;
                downloadableFileData.isSigningCompleted = isAllSignersSigned;
                downloadableFileData.fileDownloadName = this.getDisplayNameForDloadSignatureDocs(
                    report,
                    document,
                    isAllSignersSigned
                );
                downloadableFileData.storageAccountId = document.storageAccountId;
                storageAccountId = document.storageAccountId;
                if (document.size != null) {
                    let size = this.getDownloadSizeInBytes(document.size);
                    downloadableFileData.size = size;
                    zipSize += size;
                }
                downloadableDocuments.push(downloadableFileData);
            });

            if (isAllSignersSigned && report.isMigratedSigningIdForAudit) {
                let auditFileData: IDownloadableDocuments = {
                    signingId: 0,
                    documentGuid: '',
                    documentId: 0,
                    size: 0,
                    fileName: '',
                    signingGuid: '',
                    isSigningCompleted: true,
                    fileDownloadName: '',
                    storageAccountId: 0
                };
                auditFileData.signingGuid = signingGuid;
                auditFileData.signingId = id;
                auditFileData.fileName = 'Summary';
                auditFileData.isSigningCompleted = true;
                auditFileData.fileDownloadName = 'Summary';
                auditFileData.storageAccountId = storageAccountId;
                downloadableDocuments.push(auditFileData);
            }
        });
        this.setState({ downloadableDocuments: downloadableDocuments, zipFileDownloadName: zipFileName });
        zipSize = zipSize / 1024 / 1024; // Converting Bytes to MB
        if (zipSize > 5120) {
            VenusNotifier.Error(SignatureFlowReportConstants.DownloadDocument.DownloadLimitError, null);
        } else if (zipSize < 200) {
            if (selectedReports.length == 1) {
                if (selectedReports[0].signatureStatus === SignatureFlowReportSignatureStatus.DeliveryFailed) {
                    return;
                }
                zipFileName = Helper.getZipFileNameForSingleDload(selectedReports[0].title, isAllSignersSigned);
                this.setState({
                    downloadReturnState: {
                        show: true,
                        selected: downloadableDocuments,
                        saveCheckIds: undefined,
                        remove: false,
                    },
                    zipFileDownloadName: zipFileName,
                });
            } else if (this.state.defaultDownloadType == DefaultDownloadInfoType.DownloadNow) {
                VenusNotifier.Info(SignatureFlowReportConstants.DownloadDocument.BulkDownloadNotification, null);
                this.onDownloadNow(downloadableDocuments, zipFileName);
            } else if (this.state.defaultDownloadType == DefaultDownloadInfoType.PromptMeEveryTime) {
                this.setState({
                    DownloadTypeOptionState: {
                        show: true,
                        selected: undefined,
                        saveCheckIds: undefined,
                        remove: false,
                    },
                });
            } else {
                this.onDownloadLater(downloadableDocuments, zipFileName);
            }
        } else if (zipSize > 200) {
            this.onDownloadLater(downloadableDocuments, zipFileName);
        }
    }

    private onDownloadLater(downloadableDocuments: IDownloadableDocuments[], zipName: string) {
        this.setState({ showLoader: true });
        this.props.downloadLater(downloadableDocuments, zipName, () => {
            this.setState({ showLoader: false });
            this.onDownloadTypeCancel();
        });
    }

    private onDownloadNow(downloadableDocuments: IDownloadableDocuments[], zipName: string) {
        this.setState({ showLoader: true });
        this.props.downloadAllDocuments(downloadableDocuments, zipName, ArchiveReportPaths.DownloadAll, () => {
            this.setState({ showLoader: false });
            this.onDownloadTypeCancel();
        });
    }

    private onDownloadReturnAll() {
        this.onDownloadNow(this.state.downloadableDocuments, this.state.zipFileDownloadName);
    }

    private onDownloadSubmit() {
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.onDownloadNow(this.state.downloadableDocuments, this.state.zipFileDownloadName);
        } else if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads) {
            this.onDownloadLater(this.state.downloadableDocuments, this.state.zipFileDownloadName);
        }
    }

    private onDownloadIndividualFile(
        documentGuid: string,
        fileName: string,
        signingGuid: string,
        signingId: number,
        hasAllSignersSigned: boolean,
        fileDownloadName: string,
        storageAccountId: number) {
        this.setState({ showLoader: true });
        this.props.downloadIndividualFile(
            documentGuid,
            fileName,
            signingGuid,
            signingId,
            hasAllSignersSigned,
            fileDownloadName,
            storageAccountId, 
            ArchiveReportPaths.Download,
            () => {
                this.setState({ showLoader: false });
            }
        );
    }

    private onDownloadReturnCancel() {
        this.setState({
            downloadReturnState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },
        });
    }

    private onDownloadTypeCancel() {
        this.setState({
            selectedDownloadType: undefined,
        });
        this.setState({
            DownloadTypeOptionState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
        });
    }

    private onDownloadOptionChange(value: any) {
        this.setState({ selectedDownloadType: value.target.value });
    }

    //====================Download Returns End Here===============================

    //====================SendReminder Starts Here===============================

    onSendReminder(rowIndex: number = NO_INDEX) {
        let selectedIds: number[] = [];
        if (
            rowIndex > NO_INDEX && // from individual action
            this.props.ArchivedReportDocuments &&
            this.props.ArchivedReportDocuments.signatureFlowReportTableModel &&
            this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex]
        ) {
            selectedIds.push(
                this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId
            );
        } else {
            if (this.state.selectedRows.length <= 0) {
                VenusNotifier.Warning('Please select one or more documents to send reminders.', null);
                return;
            }
            selectedIds = this.selectedDocumentIds().map(x => x.id); // from header level
        }
        let selectedReports = selectedIds.map((id, i) => {
            return this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents.filter(
                x => x.signingId == id
            )[0];
        });
        if (selectedIds.length > 0) {
            if (selectedIds.length === 1) {
                this.props.getReminderSettings(
                    selectedIds[0],
                    `${ScreenIds.ArchiveReport}${ResourceIdConstants.SendReminder}`
                );
            }
            this.setState({
                sendReminderState: {
                    show: true,
                    selected: selectedReports,
                    saveCheckIds: undefined,
                    remove: false,
                },
            });
        }
    }

    sendReminderNow = (selectedSigningId: number) => {
        let rowIndex = NO_INDEX;
        if (selectedSigningId > 0) {
            rowIndex = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents.findIndex(
                x => x.signingId === selectedSigningId
            );
            this.sendRemindersForSelectedReturns(rowIndex);
        } else {
            //multi select or no selection
            if (this.state.selectedRows.length <= 0) {
                VenusNotifier.Warning('Please select one or more documents to send reminders.', null);
                return;
            }
            if (this.state.selectedRows.length > 1) {
                this.sendRemindersForSelectedReturns(rowIndex);
            } else {
                this.sendRemindersForSelectedReturns(this.state.selectedRows[0]);
            }
        }
    };

    sendRemindersForSelectedReturns = (rowIndex: number) => {
        let sendReminder: IMailData[] = [];
        let selectedDocuments: ISignatureFlowReportDocument[] = [];
        let index: number = 0;
        if (rowIndex == NO_INDEX) {
            selectedDocuments = this.selectedDocuments();
            selectedDocuments = selectedDocuments.filter(
                (x: ISignatureFlowReportDocument) =>
                    x.signatureStatus == SignatureFlowReportSignatureStatus.OutForSignature ||
                    x.signatureStatus == SignatureFlowReportSignatureStatus.Processing ||
                    x.signatureStatus == SignatureFlowReportSignatureStatus.PartiallySigned
            );

            if (selectedDocuments.length == 0) {
                VenusNotifier.Error(
                    'Error : We cannot send reminder email notification for the selected document(s).',
                    null
                );
                this.onCancelSendReminder();
                return;
            }
            for (let i = 0; i < selectedDocuments.length; i++) {
                if (!this.props.signatureFlowReportDetails[selectedDocuments[i].signingId].isSignersUpdated) {
                    this.props.requestSignatureReportSigners(
                        selectedDocuments[i].signingId,
                        `${ScreenIds.ArchiveReport}${ResourceIdConstants.SendReminder}`,
                        (error?: boolean) => {
                            if (!error) {
                                this.sendRemindersForSelectedReturns(rowIndex);
                            } else {
                                this.onCancelSendReminder();
                            }
                        }
                    );
                    return;
                }
            }
            sendReminder = this.selectedDocumentsSignersInfo();
        } else {
            selectedDocuments.push(
                this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex]
            );
            for (let i = 0; i < selectedDocuments.length; i++) {
                if (!this.props.signatureFlowReportDetails[selectedDocuments[i].signingId].isSignersUpdated) {
                    this.props.requestSignatureReportSigners(
                        this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId,
                        `${ScreenIds.ArchiveReport}${ResourceIdConstants.SendReminder}`,
                        (error?: boolean) => {
                            if (!error) {
                                this.sendRemindersForSelectedReturns(rowIndex);
                            } else {
                                this.onCancelSendReminder();
                            }
                        }
                    );
                    return;
                }
            }
            HideLoader();
            let temp = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex];
            let mailData: IMailData = {
                signers: [],
                documents: [],
                deliveredUser: { email: '', firstName: '', lastName: '', userId: 0 },
                title: '',
            };
            let nextSigners = temp.signerInfo
                .filter(
                    x =>
                        x.isSigned == 0 &&
                        (x.order > 0 ||
                            (x.order == 0 &&
                                (x.signerType == ClientTypes.Sender || x.signerType == ClientTypes.Delegatee) &&
                                x.clientId != 0))
                )
                .sort((a: ISignerModel, b: ISignerModel) => {
                    if (a.order < b.order) return -1;
                    else return 1;
                });
            if (nextSigners.length == 0) {
                VenusNotifier.Error('Error : We cannot send reminder email notification for this document.', null);
                return;
            }

            let currentOrder = nextSigners[0].order;
            nextSigners
                .filter(x => x.order == currentOrder)
                .forEach(signer => {
                    mailData.signers.push(this.checkIfSignerHasDelegatee(signer, temp));
                });
            temp.documentInfo.forEach((document, index) => {
                mailData.documents.push(document);
            });
            mailData.deliveredUser = temp.deliveredUserDetails;
            mailData.title = temp.title;
            sendReminder.push(mailData);
        }

        if (selectedDocuments.length > 0) {
            let i: number = 0;
            for (i = 0; i < selectedDocuments.length; i++) {
                let isValid: boolean = true;
                let signatureStatus: number = selectedDocuments[i].signatureStatus;
                if (
                    signatureStatus == SignatureFlowReportSignatureStatus.Cancelled ||
                    signatureStatus == SignatureFlowReportSignatureStatus.Declined ||
                    signatureStatus == SignatureFlowReportSignatureStatus.ESigned ||
                    signatureStatus == SignatureFlowReportSignatureStatus.SignatureStampingFailed ||
                    signatureStatus == SignatureFlowReportSignatureStatus.DeliveryFailed
                ) {
                    isValid = false;
                    index = sendReminder.findIndex(x => x.documents[0].signingId == selectedDocuments[i].signingId);
                    if (index != -1) {
                        sendReminder.splice(index, 1);
                    }
                }

                if (!isValid) {
                    VenusNotifier.Error('Error : We cannot send reminder email notification for this document.', null);
                }
            }
            sendReminder.length > 0 &&
                this.props.sendReminder(sendReminder, ArchiveReportPaths.SendReminder, this.onFinishSendReminder);
        }
    };

    onCancelSendReminder = () => {
        this.setState({
            sendReminderState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },
        });
    };

    onFinishSendReminder = () => {
        this.onCancelSendReminder();
        this.onPageReload();
    };

    updateSigningReminderSettings = (
        signingInfoIds: number[],
        newSettings: IReminderSettings,
        callback?: () => void
    ) => {
        if (this.state.isBulkSelectionEnabled) {
        }
        this.props.updateSigningReminderSettings(
            signingInfoIds,
            newSettings,
            ArchiveReportPaths.UpdateReminderSettings,
            callback
        );
    };

    getReminderSettings = (): IReminderSettings => {
        if (this.state.sendReminderState.selected) {
            if (this.state.sendReminderState.selected.length > 1) {
                return this.props.signatureFlowSettingsData.signatureFlowSettings.reminderSettings;
            } else {
                return this.props.signingInfoSettingsData[this.state.sendReminderState.selected[0].signingId]
                    .signingInfoSettings.reminderSettings;
            }
        }
        return initialReminderSettings;
    };

    isSendReminderModalLoading = () => {
        if (this.state.selectedRows.length > 1 && this.props.signingInfoSettingsData) {
            return this.props.signingInfoSettingsData.isLoading;
        }
        if (this.props.signingInfoSettingsData && this.state.sendReminderState.selected) {
            return (
                this.props.signingInfoSettingsData[this.state.sendReminderState.selected[0].signingId].isLoading ||
                this.props.signingInfoSettingsData.isLoading
            );
        }
        return false;
    };

    //====================SendReminder Ends Here=================================

    //====================View Access Code Starts Here===============================

    private onViewAccessCodeOpen(rowIndex: number) {
        let id = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        let signingInfo = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex];
        this.props.requestSignatureReportAccessCode(id, ArchiveReportPaths.GetOtp);

        this.setState({
            accessCodeState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
        let primaryEmailIds: string[] = signingInfo.signersEmailAddress.split(',');
        this.props.requestOnehubAccessCode(primaryEmailIds, response => {
            this.setState({
                onehubAccessCodeState: {
                    model: response,
                },
            });
        });
    }

    private onGenerateOTP(signingId: number, clientType: number, clientGUID: string, callback: () => void) {
        this.props.generateOTP(signingId, clientType, clientGUID, ArchiveReportPaths.GenerateOtp, callback);
        this.setState({
            accessCodeState: {
                show: true,
                model: this.state.accessCodeState.model,
                saveCheckId: undefined,
            },
        });
    }

    private onViewAccessCodeCancel() {
        this.setState({
            accessCodeState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }
    private onGenerateOnehubAccessCode(primaryEmailAddress: string) {
        this.props.generateOnehubAccessCode(primaryEmailAddress, (response: IOnehubAccessCodeModel) => {
            this.setState({
                onehubAccessCodeState: {
                    model: this.state.onehubAccessCodeState.model.map(entry => {
                        if (entry.clientEmail === response.clientEmail) {
                            return {
                                ...entry,
                                accessCode: response.accessCode,
                                accessCodeStatus: response.accessCodeStatus,
                                otpDate: response.otpDate,
                            };
                        }
                        return entry;
                    }),
                },
            });
        });
    }
    //=====================View Access Code Ends Here================================

    //====================Client Tracking Starts Here===============================

    private onClientTrackingOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestSignatureReportClientTracking(id, ArchiveReportPaths.ClientTracking);
        });

        let emails: Set<string> = new Set();
        if (rowIndex != NO_INDEX) {
            this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signerInfo.map(x => {
                if (x.order > -1) {
                    emails.add(x.signerEmail);
                }
            });

            let deliveredDate: string = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].deliveredOn?.toString(); 
            this.props.requestClientHubClientTrackingEvents(
                Array.from(emails), 
                deliveredDate,
                (events) => {
                    this.setState({ clientHubEvents: events });
            });
        }

        this.setState({
            clientTrackingState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
    }

    private onClientTrackingCancel() {
        this.setState({
            clientTrackingState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
            clientHubEvents: [],
        });
    }
    //=====================Client Tracking Ends Here=========================================

    //====================Unlock UTE Locked Recipients Starts Here=============================
    private onUnlockUTELockedRecipientOpen(rowIndex: number) {
        let id = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        
        this.setState({
            unlockRecipientsState: {
                showModal: true,
                isLoading: true,
                lockedEmailAddresses: [],
                lockedDirectAccessEmailAddresses: [],
                id: id,
            },
        });

        this.props.getLockedUTEEmailAddresses(id, () => {
            this.setState((prevState) => ({
                unlockRecipientsState: {
                    ...prevState.unlockRecipientsState,
                    showModal: true,
                    lockedEmailAddresses: this.props.oneHubData.lockedUTEEmailAccounts,
                    lockedDirectAccessEmailAddresses: [],
                    id: id,
                },
            }));
        });

        this.props.requestLockedDirectAccessAccounts(id, (lockedAccounts) => {
            this.setState((prevState) => ({
                unlockRecipientsState: {
                    ...prevState.unlockRecipientsState,
                    showModal: true,
                    lockedDirectAccessEmailAddresses: lockedAccounts,
                    id: id,
                },
            }));
        });
    }

    private onUnlockRecipientsModalClose() {
        this.setState({
            unlockRecipientsState: {
                showModal: false,
                isLoading: false,
                lockedEmailAddresses: [],
                lockedDirectAccessEmailAddresses: [],
                id: 0,
            },
        });
    }

    private onUnlockUTEAccount(email: string[]) {
        this.props.unlockUTERecipientAccount(email, () => {
            this.onUnlockRecipientsModalClose();
            this.onPageReload();
        });
    }

    private onUnlockDirectDocument(email:string[], signingId: number){
        this.props.unlockDirectDocument(ArchiveReportPaths.UnlockRecipients, email, signingId, ()=>{
            this.onUnlockRecipientsModalClose();
            this.onPageReload();
        });
    }
    //====================Unlock UTE Locked Recipients Ends Here===============================

    //==================== Report a problem start ===============================

    private onReportProblemOpen(rowIndex: number = NO_INDEX) {
        this.setState({
            reportProblem: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
    }

    private onReportProblemCancel() {
        this.setState({
            reportProblem: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    private onReportProblemSave(id: number, problemDetails: IReportProblemDetails, callback?: () => void) {
        this.props.reportTaxDocumentProblem(problemDetails, ArchiveReportPaths.ReportedProblem, callback);
        this.setState({
            reportProblem: {
                show: false,
                model: undefined,
                saveCheckId: id,
            },
        });

        logger.trackTrace(
            'onReportProblemSave with params' + JSON.stringify({ id: id, problemDetails: problemDetails })
        );
    }
    //====================Report a problem Ends Here===============================

    //=====================Cancel Document=======================================================================

    private onCancelDocumentConfirmation(rowIndex: number) {
        let obj = this;
        let signatureStatus: SignatureFlowReportSignatureStatus =
            this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signatureStatus;
        if (
            signatureStatus == SignatureFlowReportSignatureStatus.ESigned ||
            signatureStatus == SignatureFlowReportSignatureStatus.Declined ||
            signatureStatus == SignatureFlowReportSignatureStatus.Cancelled ||
            signatureStatus == SignatureFlowReportSignatureStatus.SignatureStampingFailed ||
            signatureStatus == SignatureFlowReportSignatureStatus.DeliveryFailed
        ) {
            VenusNotifier.Warning('You cannot cancel this document.', null);
            return;
        }
        bootbox
            .confirm({
                title: 'Document Cancellation',
                message: warningMsg.cancelDocumentConfirmation,
                buttons: {
                    cancel: {
                        label: 'Dismiss',
                        className: 'btn-white modal-footer-button-cancel',
                    },
                    confirm: {
                        label: 'Yes, Cancel It',
                        className: 'btn-danger',
                    },
                },
                callback: (result: boolean) => {
                    if (result) {
                        obj.onCancelDocumentOpen(rowIndex);
                    }
                },
            })
            .find('.modal-dialog')
            .addClass('modal-dialog-centered');
    }

    private onCancelDocumentOpen(rowIndex: number) {
        let id = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        let signatureStatus =
            this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signatureStatus;

        this.setState({
            showLoader: true,
            cancelDocumentState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
        const path =
            signatureStatus === SignatureFlowReportSignatureStatus.Declined
                ? CommonPaths.DeclinedDescription
                : ArchiveReportPaths.CancelDescription;
        this.props.requestCancelledDescription(id, signatureStatus, path, () => {
            this.setState({ showLoader: false });
        });
        logger.trackTrace('onCancelDocument with id' + JSON.stringify(id));
    }

    private onSaveCancelDocumentDescription(
        id: number,
        message: string,
        callBack: () => void,
        sendNotification: boolean
    ) {
        if (message.length == 0) {
            return VenusNotifier.Warning('Please enter the reason for cancelling this document', null);
        }
        let index = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents.findIndex(
            x => x.signingId == id
        );
        let signatureReport = { ...this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[index] };
        signatureReport.cancelledDocumentDescription = message;
        signatureReport.signatureStatus = SignatureFlowReportSignatureStatus.Cancelled;
        this.props.cancelDocument(signatureReport, ArchiveReportPaths.UpdateStatusAsCancel, callBack);
        if (sendNotification) {
            let mailData: IMailData = {
                signers: [],
                documents: [],
                deliveredUser: { email: '', firstName: '', lastName: '', userId: 0 },
                title: '',
            };

            signatureReport.signerInfo.map((signer, index) => {
                mailData.signers.push(signer);
            });
            signatureReport.documentInfo.map((document, index) => {
                mailData.documents.push(document);
            });
            mailData.deliveredUser = signatureReport.deliveredUserDetails;
            mailData.title = signatureReport.title;

            this.props.sendcanceledDocumentNotification(mailData, message, ArchiveReportPaths.CancelNotification);
        }
    }

    private onCancelDocumentCancel() {
        this.setState({
            cancelDocumentState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //======================Cancel Document Ends Here============================================================

    //================== Document Note =============================================

    private onDocumentNoteOpen(rowIndex: number) {
        this.setState(
            {
                documentNoteState: {
                    show: true,
                    model: this.getStoreItem(rowIndex),
                    saveCheckId: undefined,
                },
            },
            () => {
                this.setState({
                    documentNoteMessage:
                        this.state.documentNoteState.model != undefined
                            ? this.state.documentNoteState.model.documentNote
                            : '',
                });
            }
        );
    }

    private onDocumentNoteChange(event: any) {
        let message = event.target.getContent();
        this.setState({ documentNoteMessage: message });
    }

    private onUpdateDocumentNote() {
        let temp: any;
        if (this.state.documentNoteState.model != undefined) {
            temp = this.state.documentNoteState.model;

            if (
                this.state.documentNoteMessage &&
                this.state.documentNoteMessage.length > SignatureFlowReportConstants.DocumentNote.MessageLimit
            ) {
                VenusNotifier.Warning(SignatureFlowReportConstants.DocumentNote.MessageLimitExceedsError, null);
                return;
            }

            if (
                this.state.documentNoteMessage &&
                this.state.documentNoteMessage
                    .replace(/\&nbsp;/g, '')
                    .replace(/<\/?p[^>]*>/g, '')
                    .trim() != ''
            ) {
                const showSaveMessage = temp.documentNote == '' ? true : false;
                temp.documentNote = htmlencode.htmlEncode(this.state.documentNoteMessage);

                this.props.updateArchiveDocumentNote(
                    this.state.documentNoteState.model,
                    ArchiveReportPaths.DocumentNote,
                    undefined,
                    showSaveMessage
                );

                this.setState({
                    documentNoteState: {
                        model: temp,
                        show: false,
                        saveCheckId: undefined,
                    },
                    documentNoteMessage: '',
                });
            } else {
                VenusNotifier.Warning(SignatureFlowReportFilterConstants.ValidationMessage.EmptyDocumentNote, null);
            }
        }
    }

    private deleteDocumentNoteMessage() {
        let temp: any;
        if (this.state.documentNoteState.model != undefined) {
            temp = this.state.documentNoteState.model;
            temp.documentNote = '';
            this.props.updateArchiveDocumentNote(
                this.state.documentNoteState.model,
                ArchiveReportPaths.DocumentNote,
                true
            );

            this.setState({
                documentNoteState: {
                    model: temp,
                    show: false,
                    saveCheckId: undefined,
                },
                documentNoteMessage: '',
            });
        }
    }

    private onDocumentNoteCancel() {
        this.setState({
            documentNoteState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
            documentNoteMessage: '',
        });
    }

    //================== Document Note End ==========================================

    //================== Delete Document Starts Here ==========================================
    private onDeleteDocumentConfirmation(rowIndex: number) {
        let obj = this;
        bootbox
            .confirm({
                title: 'Delete Document(s)',
                message: DeleteSignatureConstants.ConfirmDeletion,
                buttons: {
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-white modal-footer-button-cancel',
                    },
                    confirm: {
                        label: 'Confirm',
                        className: 'btn-info modal-footer-button-save',
                    },
                },
                callback: (result: boolean) => {
                    if (result) {
                        let id =
                            obj.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex]
                                .signingId;
                        obj.props.deleteDocument(id, ArchiveReportPaths.Delete, () => {
                            this.setState({
                                page: getAdjustedReportsPageNumber(
                                    this.props.ArchivedReportDocuments.signatureFlowReportTableModel.count,
                                    1,
                                    this.state.page,
                                    this.state.pageSize
                                ),
                            });
                            obj.props.requestSignatureArchiveDocuments(
                                this.buildQuery(this.state.page, this.state.pageSize),
                                true
                            );
                        });

                        logger.trackTrace('deleteDocument with id' + id);
                    }
                },
            })
            .find('.modal-dialog')
            .addClass('modal-dialog-centered');
    }

    //================== Delete Document End ==================================================

    //====================Download History Starts Here=============================

    private onDownloadHistoryOpen(rowIndex: number = NO_INDEX) {
        let id = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        this.props.requestDownloadHistory(id);

        this.setState({
            downloadHistoryState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
    }

    private onDownloadHistoryCancel() {
        this.setState({
            downloadHistoryState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //====================Download History Ends Here===============================

    //====================Recipient Details Starts Here===============================

    private onRecipientDetailsOpen = (rowIndex: number) => {
        this.setState({
            recipientDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
    };

    private onRecipientDetailsCancel = () => {
        this.setState({
            recipientDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    };
    private updateSignersEmail = (signatureData: ISignatureFlowReportDocument, signers: ISignerModel[]) => {
        this.props.updateArchiveSignerEmail(
            signatureData,
            signers,
            `${ScreenIds.ArchiveReport}${ResourceIdConstants.EditClientDetails}`
        );
        this.onRecipientDetailsCancel();
    };
    //====================Recipient Details Ends Here===============================

    // =================Edit Client Details starts here=======================
    private onEditClientDetailsOpen = (rowIndex: number = NO_INDEX) => {
        this.setState({
            editClientInfoState: {
                show: true,
                model: this.getStoreItem(rowIndex),
                saveCheckId: undefined,
            },
        });
        let id = this.props.ArchivedReportDocuments.signatureFlowReportTableModel.documents[rowIndex].signingId;
        this.props.requestSigningInfo(id, ArchiveReportPaths.SigningInfo);
        this.props.requestCompanySignatureSettings();
        if (this.props.contactsStore.contacts.length === 0) {
            this.props.getContacts();
        }
    };

    private onEditClientDetailsCancel = (rowIndex: number = NO_INDEX) => {
        this.setState({
            locationMismatchModalState: { ...initialLocationMismatchModalState },
            clientInfo: { ...initialClientInfoViewModel },
            clientInfoComparisonResult: { ...initialClientInfoComparisonResultViewModel },
            clientInfoMismatchModalState: { ...initialClientInfoMismatchModalState },
            compareClientInfoProgress: false,
            tempSigninfo: initialSigningInfo,
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    };

    updateRecipients = (signingInfoId: number, recipients: IClient[], callback?: () => void) => {
        this.props.updateRecipients(signingInfoId, recipients, ArchiveReportPaths.UpdateAuthentication, callback);
    };

    // =================Edit Client Details ends here=======================

    //====================Change Office Location  Starts Here===============================

    private onChangeOfficeLocationOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Error('Please select documents to change office location.', null);
            return;
        }
        this.setState({
            changeOfficeLocationState: {
                show: true,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
        });
    }

    private onChangeOfficeLocationContinue = (locationId: number, contactPersonId: number, callBack: () => void) => {
        let docInfo: { id: number; recipientName: string }[] = this.selectedDocumentIds();
        this.props.changeOfficeLocation(
            docInfo,
            locationId,
            contactPersonId,
            ArchiveReportPaths.ChangeOfficeLocation,
            status => {
                this.onChangeOfficeLocationCallBack(status, callBack);
            }
        );
    };

    onChangeOfficeLocationCallBack = (status: string, callBack: () => void) => {
        this.setState({
            changeOfficeLocationState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
            selectedRows: [],
            deSelectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,
        });

        if (status === 'success') {
            this.onPageReload();
        }

        callBack();
    };

    private onChangeOfficeLocationCancel = () => {
        this.setState({
            changeOfficeLocationState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
        });
    };

    getUpdatedClientInfo = (create?: boolean) => {
        const locationName = this.props.officeLocationState.locationDropdown.find(
            c => c.value == this.state.tempSigninfo.locationId
        )?.name;
        let _clientInfo = { ...this.state.clientInfo };
        _clientInfo.name = this.state.tempSigninfo.recipientName;
        _clientInfo.clientId = trim(this.state.tempSigninfo.clientId);
        _clientInfo.locationId = this.state.tempSigninfo.locationId;
        _clientInfo.locationName = locationName;
        if (create) {
            _clientInfo = Helper.cleanClientManagementModal(_clientInfo);
        }
        return _clientInfo;
    };

    prepareLocationMismatchCallback = (callBack: () => void) => {
        getClientInfoByClientId(
            this.state.tempSigninfo.clientId,
            `${ScreenIds.ArchiveReport}${ResourceIdConstants.EditClientDetails}`,
            (data: IClientInfoViewModel) => {
                if (data && !isEmpty(data) && !isEmpty(data.name) && trim(data.name).length > 0) {
                    this.setState(
                        {
                            clientInfo: data,
                        },
                        () => {
                            callBack();
                        }
                    );
                } else {
                    this.onCompareClientInfo();
                }
            }
        );
    };

    compareClientInfoCallback = (
        compareClientInfoData: IClientInfoComparisonResultViewModel,
        clientInfo: IClientInfoViewModel
    ) => {
        if (compareClientInfoData.comparisonStatus == ClientInfoComparisionCode.NoDataMismatch) {
            this.props.updateDocumentClientInfo(
                this.state.editClientInfoState.model.signingId,
                this.state.tempSigninfo,
                ArchiveReportPaths.UpdateDocumentSigningInfo,
                this.state.clientInfoMismatchModalState.clientInfoMismatchCallback
            );
        } else if (compareClientInfoData.comparisonStatus == ClientInfoComparisionCode.NewClient) {
            this.newClientCallback(clientInfo);
        } else {
            this.clientDataMisMatchCallback(compareClientInfoData, clientInfo);
        }
    };

    setClientInfoComparisonResult = (clientInfoComparisonResult: IClientInfoComparisonResultViewModel) => {
        this.setState({ clientInfoComparisonResult }, () => {
            this.setState({
                editClientInfoState: {
                    ...this.state.editClientInfoState,
                    show: false,
                },
                clientInfoMismatchModalState: {
                    ...this.state.clientInfoMismatchModalState,
                    showModal: true,
                    isLoading: false,
                    clientBasicInfoId: 0,
                },
            });
        });
    };

    newClientCallback = (clientInfo: IClientInfoViewModel) => {
        this.setClientInfoComparisonResult(setNewClientInfoComparisonResult(clientInfo));
    };

    onCompareClientInfo = async () => {
        this.setState({ compareClientInfoProgress: true }, async () => {
            await CompareClientInfo(
                this.getUpdatedClientInfo(),
                `${ScreenIds.ArchiveReport}${ResourceIdConstants.EditClientDetails}`,
                this.compareClientInfoCallback
            );
        });
    };

    clientDataMisMatchCallback = (
        compareClientInfoData: IClientInfoComparisonResultViewModel,
        clientInfo: IClientInfoViewModel
    ) => {
        getClientInfoByClientId(
            clientInfo.clientId,
            `${ScreenIds.ArchiveReport}${ResourceIdConstants.EditClientDetails}`,
            (data: IClientInfoViewModel) => {
                this.setState({
                    clientInfoComparisonResult: compareClientInfoData,
                    clientInfo: data,
                    editClientInfoState: {
                        ...this.state.editClientInfoState,
                        show: false,
                    },
                    clientInfoMismatchModalState: {
                        ...this.state.clientInfoMismatchModalState,
                        showModal: true,
                        isLoading: false,
                        clientBasicInfoId: data.clientBasicInfoId,
                    },
                });
            }
        );
    };

    private checkClientManagement = (signingInfo: ISigningInfo, callBack: () => void) => {
        if (this.isHaveOfficeLocationAccess(signingInfo)) {
            this.setState(
                {
                    tempSigninfo: { ...signingInfo },
                    clientInfoMismatchModalState: {
                        ...this.state.clientInfoMismatchModalState,
                        isLoading: true,
                        clientInfoMismatchCallback: callBack,
                    },
                },
                () => {
                    if (Helper.isNullOrEmpty(signingInfo?.clientId)) {
                        this.props.updateDocumentClientInfo(
                            this.state.editClientInfoState.model.signingId,
                            this.state.tempSigninfo,
                            ArchiveReportPaths.UpdateDocumentSigningInfo,
                            callBack
                        );
                    } else {
                        this.prepareLocationMismatchCallback(async () => {
                            await this.onCompareClientInfo();
                        });
                    }
                }
            );
        } else {
            this.setState({
                locationMismatchModalState: {
                    ...this.state.locationMismatchModalState,
                    isModalOpen: true,
                    locationMissmatchCallback: () =>
                        this.props.updateDocumentClientInfo(
                            this.state.editClientInfoState.model.signingId,
                            this.state.tempSigninfo,
                            ArchiveReportPaths.UpdateDocumentSigningInfo,
                            callBack
                        ),
                },
                tempSigninfo: { ...signingInfo },
            });
        }
    };

    private locationMismatchModalClose = () => {
        this.setState({
            locationMismatchModalState: { ...initialLocationMismatchModalState },
            tempSigninfo: initialSigningInfo,
        });
    };

    private locationMismatchModalContinue = () => {
        if (Helper.isNullOrEmpty(this.state.tempSigninfo?.clientId)) {
            this.setState(
                { clientInfoMismatchModalState: { ...this.state.clientInfoMismatchModalState, isLoading: true } },
                () => {
                    this.state.locationMismatchModalState.locationMissmatchCallback();
                    this.locationMismatchModalClose();
                }
            );
            return;
        }
        this.setState(
            {
                clientInfoMismatchModalState: {
                    ...this.state.clientInfoMismatchModalState,
                    isLoading: true,
                    clientInfoMismatchCallback: this.state.locationMismatchModalState.locationMissmatchCallback,
                },
                locationMismatchModalState: { ...this.state.locationMismatchModalState, isModalOpen: false },
            },
            () => {
                this.prepareLocationMismatchCallback(async () => {
                    await this.onCompareClientInfo();
                });
            }
        );
    };

    private clientInfoMismatchModalClose = (canUpdate?: boolean) => {
        if (canUpdate) {
            this.props.updateDocumentClientInfo(
                this.state.editClientInfoState.model.signingId,
                this.state.tempSigninfo,
                ArchiveReportPaths.UpdateDocumentSigningInfo,
                this.state.clientInfoMismatchModalState.clientInfoMismatchCallback
            );
        } else {
            this.setState({
                clientInfoMismatchModalState: { ...initialClientInfoMismatchModalState },
                tempSigninfo: { ...initialSigningInfo },
                editClientInfoState: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined,
                },
            });
        }
    };

    isHaveOfficeLocationAccess = (signingInfo: ISigningInfo) => {
        return (
            this.props.userOfficeLocationState.userLocation.find(i => i.locationId == signingInfo?.locationId) !=
            undefined
        );
    };

    onAddClientInfo = () => {
        this.setState(
            { clientInfoMismatchModalState: { ...this.state.clientInfoMismatchModalState, isLoading: true } },
            () => {
                addClientInfo(
                    this.getUpdatedClientInfo(true),
                    `${ScreenIds.ArchiveReport}${ResourceIdConstants.EditClientDetails}`,
                    () => {
                        this.props.updateDocumentClientInfo(
                            this.state.editClientInfoState.model.signingId,
                            this.state.tempSigninfo,
                            ArchiveReportPaths.UpdateDocumentSigningInfo,
                            this.state.clientInfoMismatchModalState.clientInfoMismatchCallback
                        );
                    }
                );
            }
        );
    };

    onEditClientInfo = () => {
        this.setState(
            { clientInfoMismatchModalState: { ...this.state.clientInfoMismatchModalState, isLoading: true } },
            () => {
                editClientInfo(
                    this.getUpdatedClientInfo(),
                    `${ScreenIds.ArchiveReport}${ResourceIdConstants.EditClientDetails}`,
                    () => {
                        this.props.updateDocumentClientInfo(
                            this.state.editClientInfoState.model.signingId,
                            this.state.tempSigninfo,
                            ArchiveReportPaths.UpdateDocumentSigningInfo,
                            this.state.clientInfoMismatchModalState.clientInfoMismatchCallback
                        );
                    }
                );
            }
        );
    };

    //====================Change Office Location Ends Here===============================

    public render() {
        let documentNoteMessage = this.state.documentNoteState.model;
        const btnType = documentNoteMessage != undefined && documentNoteMessage.documentNote ? 'Update' : 'Save';
        let _defaultFilter = this.props.signatureFlowReportFilter.archivedReportsFilter.find(x => x.isDefault == 1);
        let defaultFilter =
            !this.props.ArchivedReportDocuments.isLoading &&
            !this.props.signatureFlowReportFilter.isLoading &&
            _defaultFilter
                ? _defaultFilter.name
                : undefined;

        return (
            <div className="user-assignment-content signatureflow-report">
                {
                    <div>
                        <SignatureFlowReportsHeader
                            screenId={ScreenIds.ArchiveReport}
                            pageTitle={this.props.pageTitle}
                            onSetAccessOpen={this.onSetAccessOpen}
                            onDownloadDocuments={this.onDownloadReturnOpen}
                            onDeleteDocuments={this.onDeleteDocumentsOpen}
                            onSendReminder={this.onSendReminder}
                            selectedDocumentCount={this.selectedDocumentIds().length}
                            onRetoreDocumentsOpen={this.onRestoreDocumentsOpen}
                            isBulkSelectionEnabled={false}
                            onChangeOfficeLocationOpen={this.onChangeOfficeLocationOpen}
                        />
                        <br />

                        <SignatureArchiveReportTable
                            screenId={ScreenIds.ArchiveReport}
                            ref="SignatureArchiveReportTable"
                            pageNo={this.state.page}
                            totalRows={this.props.ArchivedReportDocuments.totalRowCount}
                            pageSize={this.state.pageSize}
                            onSortChange={this.onSortChange}
                            onSearchChange={this.onSearchChange}
                            onFilterChange={this.onFilterChange}
                            onExportToExcel={this.onExportToExcel}
                            onFilterNameChange={this.onFilterNameChange}
                            onFilterSave={this.onFilterSave}
                            onFilterUpdate={this.onFilterUpdate}
                            onFilterDelete={this.onFilterDelete}
                            filterList={this.props.signatureFlowReportFilter.archivedReportsFilter}
                            currentFilter={this.state.filter}
                            onSetDefaultFilter={this.onSetDefaultFilter}
                            onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                            saveFilterShow={this.state.saveFilterShow}
                            onSaveFilterHide={this.onSaveFilterHide}
                            onSaveFilterShow={this.onSaveFilterShow}
                            isLoading={this.props.ArchivedReportDocuments.isLoading}
                            onRowSelect={this.onRowSelect}
                            onSelectAll={this.onSelectAll}
                            selectedRows={this.state.selectedRows}
                            defaultFilter={defaultFilter}
                            loadGrid={this.loadSignatureFlowReports}
                            onSignersDetailOpen={this.onSignersDetailOpen}
                            onResendAccessLinkOpen={this.onResendAccessLinkOpen}
                            onDocumentDetailsOpen={this.onDocumentDetailsOpen}
                            onDownloadReturnOpen={this.onDownloadIndividualOpen}
                            onDeleteDocumentsOpen={this.onDeleteDocumentsOpen}
                            onRetoreDocumentsOpen={this.onRestoreDocumentsOpen}
                            onSetAccessOpen={this.onSetAccessOpen}
                            onClientTrackingOpen={this.onClientTrackingOpen}
                            onReportProblemOpen={this.onReportProblemOpen}
                            onViewAccessCodeOpen={this.onViewAccessCodeOpen}
                            onDocumentNoteOpen={this.onDocumentNoteOpen}
                            onCancelDocumentOpen={this.onCancelDocumentOpen}
                            onCancelDocumentConfirmation={this.onCancelDocumentConfirmation}
                            onDeleteDocumentConfirmation={this.onDeleteDocumentConfirmation}
                            onSendReminder={this.onSendReminder}
                            onDownloadHistoryOpen={this.onDownloadHistoryOpen}
                            signatureReportsData={this.props.ArchivedReportDocuments.signatureFlowReportTableModel}
                            reportCustomColumns={this.state.customColumns}
                            saveReportCustomColumns={this.props.saveSignatureArchiveReportCustomColumn}
                            userBasicProfile={this.props.profile}
                            onRecipientDetailsOpen={this.onRecipientDetailsOpen}
                            onPageReload={this.onPageReload}
                            onEditClientDetailsOpen={this.onEditClientDetailsOpen}
                            officeLocation={Helper.setUserOfficeLocation(
                                this.props.userOfficeLocationState.userLocation
                            )}
                            getLinkedMergeDetails={this.props.getLinkedMergeDetails}
                            getPrimaryUTELinkedEmail={this.props.receivePrimaryUTELinkedEmail}
                            onUnlockRecipientsOpen={this.onUnlockUTELockedRecipientOpen}
                        />
                        <br />
                        <div style={{ height: '35px' }}>
                            <Pagination
                                totalCount={this.state.totalRecordsCount}
                                pageIndex={this.state.page}
                                pageSize={this.state.pageSize}
                                defaultPageSize={pageSize}
                                goToPage={this.onPageChange}
                                sizePerPageList={SizePerPageList}
                                showSizePerPageList={true}
                                showGoToPages={true}
                                showpageCount={false}
                                ShowListAndGoto={true}
                            />
                        </div>
                    </div>
                }
                <SignatureFlowESignDetailsModal
                    show={this.state.signerDetailsState.show}
                    signatureReport={this.state.signerDetailsState.model as ISignatureFlowReportDocument}
                    onCancel={this.onSignersDetailCancel}
                    pageTitle={this.props.pageTitle}
                />

                <SignatureFlowReportRecipientModal
                    show={this.state.recipientDetailsState.show}
                    signatureReport={this.state.recipientDetailsState.model as ISignatureFlowReportDocument}
                    onCancel={this.onRecipientDetailsCancel}
                    updateSigners={this.updateSignersEmail}
                />

                <SignatureFlowReportDocumentModal
                    show={this.state.documentDetailsState.show}
                    signatureReport={this.state.documentDetailsState.model as ISignatureFlowReportDocument}
                    onCancel={this.onDocumentDetailsCancel}
                />

                <SignatureFlowReportSetAccessModal
                    showEditAccess={this.state.setAccessState.show}
                    onCancel={this.onSetAccessCancel}
                    selectedReports={this.state.setAccessState.selected as ISignatureFlowReportDocument[]}
                    count={this.state.selectedRows.length}
                    users={this.props.userStore.users}
                    onApplyAccess={this.onSetAccessSave}
                    setAccessHeaderTextDisplay={this.state.setAccessHeaderTextDisplay}
                />

                <SignatureFlowReportDocumentNoteModal
                    showState={this.state.documentNoteState.show}
                    onHide={this.onDocumentNoteCancel}
                    changeStateTinymceBody={this.onDocumentNoteChange}
                    signatureReport={this.state.documentNoteState.model as ISignatureFlowReportDocument}
                    updateButtonClick={this.onUpdateDocumentNote}
                    deleteDocumentNote={this.deleteDocumentNoteMessage}
                    buttonText={btnType}
                />

                <ResendAccessLinkModal
                    show={this.state.resendAccessLinkState.show}
                    model={this.state.resendAccessLinkState.model as ISignatureFlowReportDocument}
                    onResendAccessLink={this.onResendAccessLink}
                    onCancel={this.onResendAccessLinkCancel}
                />

                <SignatureFlowReportCancelDocument
                    show={this.state.cancelDocumentState.show}
                    showLoader={this.state.showLoader}
                    selectedDocument={this.state.cancelDocumentState.model as ISignatureFlowReportDocument}
                    onSaveCancelDocumentDescription={this.onSaveCancelDocumentDescription}
                    onCancel={this.onCancelDocumentCancel}
                />

                <ViewAccessCode
                    show={this.state.accessCodeState.show}
                    onCancel={this.onViewAccessCodeCancel}
                    selectedDocument={this.state.accessCodeState.model as ISignatureFlowReportDocument}
                    onGenerateOTP={this.onGenerateOTP}
                    oneHubAccessModel={this.state.onehubAccessCodeState.model as IOnehubAccessCodeModel[]}
                    onGenerateOnehubAccessCode={this.onGenerateOnehubAccessCode}
                />

                <SignatureFlowReportClientTrackingModal
                    show={this.state.clientTrackingState.show}
                    onCancel={this.onClientTrackingCancel}
                    selectedDocument={this.state.clientTrackingState.model as ISignatureFlowReportDocument}
                    clientHubEvents={this.state.clientHubEvents}
                />

                <ReportProblemModal
                    onCancel={this.onReportProblemCancel}
                    sfModel={this.state.reportProblem.model}
                    onReportProblemSave={this.onReportProblemSave}
                    show={this.state.reportProblem.show}
                    loggedInCPA={this.props.profile}
                    companyName={this.props.companyData.companyProfile.companyInfo.companyName}
                    reportProblemType={ReportProblemTypes.SignatureSpecific}
                />

                <SignatureFlowReportDownloadDocuments
                    show={this.state.downloadReturnState.show}
                    onCancel={this.onDownloadReturnCancel}
                    downloadableDocuments={this.state.downloadReturnState.selected as IDownloadableDocuments[]}
                    downloadIndividual={this.onDownloadIndividualFile}
                    downloadAll={this.onDownloadReturnAll}
                    showLoader={this.state.showLoader}
                />
                <DownloadTypeOption
                    show={this.state.DownloadTypeOptionState.show}
                    onCancel={this.onDownloadTypeCancel}
                    onDownloadOptionChange={this.onDownloadOptionChange}
                    selectedDownloadType={this.state.selectedDownloadType}
                    onDownloadOptionSave={this.onDownloadSubmit}
                    showLoader={this.state.showLoader}
                />

                <SignedDocumentDownloadHistory
                    users={this.props.userStore.users}
                    show={this.state.downloadHistoryState.show}
                    onCancel={this.onDownloadHistoryCancel}
                    selectedDocument={this.state.downloadHistoryState.model as ISignatureFlowReportDocument}
                />

                <EditClientInfoModal
                    contacts={this.props.contactsStore.contacts}
                    refreshPage={this.onPageReload}
                    onCancel={this.onEditClientDetailsCancel}
                    show={this.state.editClientInfoState.show}
                    clientAuthenticationData={this.props.sfStore}
                    updateRecipients={this.updateRecipients}
                    getAuthenticationParameter={this.props.getAuthenticationParameter}
                    questions={this.props.authenticationQuestionsData.authenticationQuestions}
                    companySettings={this.props.signatureFlowSettingsData.signatureFlowSettings}
                    selectedDocument={this.state.editClientInfoState.model as ISignatureFlowReportDocument}
                    signingInfo={
                        this.state.editClientInfoState.model
                            ? this.props.signingInfoData[this.state.editClientInfoState.model.signingId].signingInfo
                            : initialSigningInfo
                    }
                    isLoading={
                        this.state.editClientInfoState.model
                            ? this.props.signingInfoData[this.state.editClientInfoState.model.signingId].isLoading ||
                              this.state.clientInfoMismatchModalState.isLoading
                            : true
                    }
                    officeLocationList={Helper.prepareDropDownData(this.props.officeLocationState.locationDropdown)}
                    checkClientManagement={this.checkClientManagement}
                />
                <SendReminderModal
                    show={this.state.sendReminderState.show}
                    selectedReports={this.state.sendReminderState.selected as ISignatureFlowReportDocument[]}
                    onCancel={this.onCancelSendReminder}
                    reminderSettings={this.getReminderSettings()}
                    sendReminderNow={this.sendReminderNow}
                    isLoading={this.isSendReminderModalLoading()}
                    updateSigningReminderSettings={this.updateSigningReminderSettings}
                    signingInfoId={this.state.sendReminderState.selected ? this.state.sendReminderState.selected[0] : 0}
                    filterSignatureStatus={this.state.filterSignatureStatus ? this.state.filterSignatureStatus : ''}
                    isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                />

                <ChangeOfficeLocationModal
                    show={this.state.changeOfficeLocationState.show}
                    onCancel={this.onChangeOfficeLocationCancel}
                    officeLocationList={Helper.prepareDropDownData(this.props.officeLocationState.locationDropdown)}
                    onChangeOfficeLocationContinue={this.onChangeOfficeLocationContinue}
                />

                <LocationMismatchModal
                    show={this.state.locationMismatchModalState.isModalOpen}
                    onCancel={this.locationMismatchModalClose}
                    onContinue={this.locationMismatchModalContinue}
                    locationName={
                        this.state.tempSigninfo.locationId == 0
                            ? ''
                            : this.props.officeLocationState.locationDropdown.find(
                                  x => x.value == this.state.tempSigninfo.locationId
                              )?.name
                    }
                />

                <ClientInfoMismatchModal
                    show={this.state.clientInfoMismatchModalState.showModal}
                    clientInfo={this.state.clientInfo}
                    clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                    clientInfoMismatchModalClose={this.clientInfoMismatchModalClose}
                    isLoading={this.state.clientInfoMismatchModalState.isLoading}
                    onAddClientInfo={this.onAddClientInfo}
                    onUpdateClientInfo={this.onEditClientInfo}
                />

                <UnlockRecipientsModal
                    show={this.state.unlockRecipientsState.showModal}
                    isLoading={this.state.unlockRecipientsState.isLoading}
                    lockedEmailAddresses={this.state.unlockRecipientsState.lockedEmailAddresses}
                    directAccessLockedEmailAddresses={this.state.unlockRecipientsState.lockedDirectAccessEmailAddresses}
                    onCancel={this.onUnlockRecipientsModalClose}
                    onUnlockOneHubAccount={this.onUnlockUTEAccount}
                    onUnlockDirectDocument={this.onUnlockDirectDocument}
                    signingId={this.state.unlockRecipientsState.id}
                />
            </div>
        );
    }
}
