import { IOnehubAccessCodeModel } from '../../components/common/OneHubAccessCodeModal';
import { API_BASE_URL, SSSUITE_API_BASE_URL } from 'src/utils/AppConstants';
import { AppThunkAction } from '..';
import { handleResponse } from '../Library';
import { addTask } from 'domain-task';
import { actionTypes } from '../ActionTypes';
import { Reducer } from 'redux';
import { SignatureFlowReportConstants } from 'src/SignatureFlow/components/helper/Constants';
import { NotificationAction, StatusType } from './NotificationStore';
import { logger } from 'src/routes/LoggedIn';
import { VenusNotifier } from 'src/SignatureFlow/components/helper/VenusNotifier';
import { IClientHubClientTrackingModel } from 'src/SignatureFlow/models/SignatureFlowReportState';

export interface IOneHubState {
    isLoading: boolean;
    lockedUTEEmailAccounts: string[];
}

const unloadedState: IOneHubState = {
    isLoading: false,
    lockedUTEEmailAccounts: [],
};

export interface RequestLockedUTEAccountsAction {
    type: actionTypes.REQUEST_LOCKED_EMAIL_ADDRESSES;
    isLoading: boolean;
}

export interface ReceiveLockedUTEAccountsAction {
    type: actionTypes.RECEIVE_LOCKED_EMAIL_ADDRESSES;
    lockedUTEEmailAccounts: string[];
    isLoading: boolean;
}

export interface UnlockUTEAccountsAction {
    type: actionTypes.UNLOCK_LOCKED_EMAIL_ADDRESSES;
    isLoading: boolean;
}

export interface ReceiveOnehubAccessCodeAction {
    oneHubAccessCodeDetails: IOnehubAccessCodeModel[];
}

export interface GenerateOnehubAccesCodeAction {
    oneHubAccessCodeModel: IOnehubAccessCodeModel;
}

type KnownAction =
    | ReceiveOnehubAccessCodeAction
    | GenerateOnehubAccesCodeAction
    | RequestLockedUTEAccountsAction
    | ReceiveLockedUTEAccountsAction
    | UnlockUTEAccountsAction
    | NotificationAction;
type dispatchAction = RequestLockedUTEAccountsAction | ReceiveLockedUTEAccountsAction | UnlockUTEAccountsAction;

export const actionCreators = {
    getLockedUTEEmailAddresses:
        (signingId: number, callback: () => void): AppThunkAction<KnownAction> =>
        dispatch => {
            const fetchTask = fetch(
                API_BASE_URL + `api/SignatureFlow/SignatureFlowReportPopup/GetLockedUTEAccounts/${signingId}`,
                {
                    method: 'GET',
                    credentials: 'include',
                }
            )
                .then(handleResponse)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_LOCKED_EMAIL_ADDRESSES,
                        lockedUTEEmailAccounts: data,
                        isLoading: false,
                    });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.FetchLockedRecipients.Error,
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `getLockedUTEEmailAddresses failed for the request having Signing Id : ${signingId} with error ${error.message}`
                    );
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_LOCKED_EMAIL_ADDRESSES, isLoading: true });
        },
    unlockUTERecipientAccount:
        (emailAddresses: string[], callback: () => void): AppThunkAction<KnownAction> =>
        dispatch => {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + `api/one-hub/unlock-accounts`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify(emailAddresses)
            })
                .then(handleResponse)
                .then(data => {
                    if (data) {
                        VenusNotifier.Success(SignatureFlowReportConstants.UnlockClientPortal.Success, null);
                        if (callback) {
                            callback();
                        }
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: SignatureFlowReportConstants.UnlockClientPortal.Error,
                        statusType: StatusType.Error,
                    });
                    logger.trackError(
                        `unlockUTERecipientAccount failed for the request having Email Address : ${emailAddresses} with error ${error.message}`
                    );
                });
            addTask(fetchTask);
        },
    requestOnehubAccessCode:
        (
            primaryEmails: string[],
            callback?: (response: IOnehubAccessCodeModel[]) => void,
            resourceId: string = ''
        ): AppThunkAction<KnownAction> =>
        () => {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + 'api/one-hub/access-code', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'X-Resource-Id': resourceId,
                    Accept: 'application/json, */*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(primaryEmails),
            })
                .then(handleResponse)
                .then(response => response as Promise<IOnehubAccessCodeModel[]>)
                .then(data => {
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {});
            addTask(fetchTask);
        },

    generateOnehubAccessCode:
        (
            primaryEmailAddress: string,
            callBack?: (response: IOnehubAccessCodeModel) => void,
            resourceId: string = ''
        ): AppThunkAction<KnownAction> =>
        () => {
            const fetchTask = fetch(
                SSSUITE_API_BASE_URL + 'api/one-hub/generate-access-code?primaryEmail=' + primaryEmailAddress,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        // RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value,
                        'X-Resource-Id': resourceId,
                    },
                }
            )
                .then(handleResponse)
                .then(response => {
                    if (callBack) {
                        callBack(response);
                    }
                })
                .catch(error => {});
            addTask(fetchTask);
        },

    requestClientHubClientTrackingEvents:
        (
            emailIds: string[],
            deliveredDate: string,
            callback: (oneHubEvents: IClientHubClientTrackingModel[]) => void
        ): AppThunkAction<KnownAction> =>
        dispatch => {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + 'api/one-hub/client-tracking', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json, */*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    emailIds: emailIds,
                    returnDeliveredDate: deliveredDate,
                }),
            })
                .then(handleResponse)
                .then(response => response as Promise<IClientHubClientTrackingModel[]>)
                .then(data => {
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    const statusMessage: any = error.statusText?.message ?? error.statusText;
                    if (typeof statusMessage === 'string') {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: statusMessage,
                            statusType: StatusType.Error,
                        });
                    }
                });
            addTask(fetchTask);
        },
};

export const reducer: Reducer<IOneHubState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as dispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_LOCKED_EMAIL_ADDRESSES:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.RECEIVE_LOCKED_EMAIL_ADDRESSES:
            return {
                ...state,
                isLoading: false,
                lockedUTEEmailAccounts: action.lockedUTEEmailAccounts,
            };
        case actionTypes.UNLOCK_LOCKED_EMAIL_ADDRESSES:
            return {
                ...state,
                isLoading: true,
            };
        default:
            return state;
    }
};
