import * as React from 'react';
import { FC, useEffect, useState,useRef} from 'react';
import { Button, Col, FormControl, Modal, Row } from 'react-bootstrap';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { ISendForSignatureData } from '../../../store/SFStore';
import { ISignatureFlowSettings } from '../../../models/SignatureFlowSettings';
import { ISignatureFlowReportDocument, SignatureFlowReportSignatureStatus } from '../../../models/SignatureFlowReportState';
import { IAuthenticationQuestionDictionary } from '../../../store/settings/AuthenticationQuestions/AuthenticationQuestionsStore';
import { AuthenticationType, ClientType, IClient, IContact, initialSigningInfo, ISigningInfo } from '../../../models/SigningInfo';
import { ClientAuthenticationList, ClientDetailsMode } from '../../settings/ClientAuthenticationList';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { isAuthenticationValid, isValidEmailAddress } from '../../helper/Validations';
import { SFStep2Constants } from '../../helper/Constants';
import * as Helper from '../../helper/HelperFunctions';
import DropdownComponent from '../../common/DropdownComponent';
import { Asterisk, DropdownArrow } from '../../svg/CustomSvgIcons';

interface IEditClientInfoProps {
    show: boolean;
    onCancel: () => void;
    contacts: IContact[];
    isLoading: boolean;
    signingInfo: ISigningInfo;
    refreshPage: () => void,
    companySettings: ISignatureFlowSettings;
    questions: IAuthenticationQuestionDictionary;
    selectedDocument: ISignatureFlowReportDocument;
    clientAuthenticationData: ISendForSignatureData;
    getAuthenticationParameter: (email: string, authenticationType: AuthenticationType, callback: () => void, question?: string) => void;
    updateRecipients: (signingInfoId: number, recipients: IClient[], callback?: () => void) => void;
    officeLocationList: any;
    checkClientManagement?: (signingInfo: ISigningInfo, callback: () => void) => void;
}

export const EditClientInfoModal:FC<IEditClientInfoProps> = (props) => {

    const [signingInfo, setSigningInfo] = useState<ISigningInfo>(Object.assign({}, initialSigningInfo));    
    const editClientAuthenticationListRef = useRef(null);

    useEffect(() => {
        if (props.signingInfo) {
            let signingInfo = Object.assign({}, props.signingInfo);
            setSigningInfo(signingInfo);
        }
    }, [props.signingInfo]);

    const updateRecipient = (recipient: IClient) => {
        let index = signingInfo.recipients.findIndex(m => m.clientGuid == recipient.clientGuid)
        let newSigningInfo = Object.assign({}, signingInfo);
        newSigningInfo.recipients[index] = recipient;
        setSigningInfo(newSigningInfo);
    }

    const onCancelButtonClick = (event: any) => {
        setSigningInfo(Object.assign({}, initialSigningInfo));
        props.onCancel();
    }

    const onSave = (event: any) => {

        // reset delegator signing order to 0 before saving
        resetDelegatorsOrder();
        let recipients = signingInfo.recipients;
        if (validateClientInformation(signingInfo) && validateClients(recipients)) {
                props.checkClientManagement(signingInfo, () => {
                    props.updateRecipients(props.selectedDocument.signingId, recipients, props.refreshPage);
                    setSigningInfo(Object.assign({}, initialSigningInfo));
                    props.onCancel();
                });
        }
    }

    const resetDelegatorsOrder = () => {
        if(signingInfo.recipients.length > 0) {
            signingInfo.recipients.map(r => {
                if (r.delegateeId > 0) {
                    r.signingOrder = 0;
                }
            })
        }
    }

    const setDelegatorsOrder = () => {
        if(signingInfo.recipients.length > 0) {
            signingInfo.recipients.map(r => {
                if (r.delegateeId > 0) {
                    r.signingOrder = signingInfo.recipients.find(m => m.id == r.delegateeId).signingOrder;
                }
            })
        }
    }

    const validateClientInformation = (signingInfo: ISigningInfo) => {
        let isValid: boolean = true;

        if(props.companySettings.clientIdSettings.mandateClientId && Helper.isNullOrEmpty(signingInfo.clientId)) {
            VenusNotifier.Warning(SFStep2Constants.clientIdRequired, null);
            isValid = false;
        }

        else if(Helper.isNullOrEmpty(signingInfo.recipientName)) {
            VenusNotifier.Warning(SFStep2Constants.clientNameRequired, null);
            isValid = false;
        }
        else if(signingInfo.locationId == 0) {
            VenusNotifier.Warning(SFStep2Constants.officelocationRequired, null);
            isValid = false;
        }
        return isValid;
    }

    const isRecipientNameValid = (recipients: IClient[]) => {
        if(editClientAuthenticationListRef && editClientAuthenticationListRef.current) {
            const filterEditableRecipientNameFields = recipients.filter(r => !editClientAuthenticationListRef.current?.isSigned(r));
            return filterEditableRecipientNameFields.map(r => Helper.isNullOrEmpty(r.name)).every((x: boolean) => x == false)
        }
        return false;
    }


    const validateClients = (recipients: IClient[]) => {
        let isValid: boolean = true;
        let filteredRecipients = recipients.filter(m => m.clientType !== ClientType.Sender && m.clientType !== ClientType.Delegatee);
        if (filteredRecipients.filter(r => r.clientType.toString() === ClientType.Signer.toString()).length === 0) {
            isValid = false;
            VenusNotifier.Warning(SFStep2Constants.EmptySignerWarning, null);
        }
        if(!isRecipientNameValid(filteredRecipients)) {
            VenusNotifier.Warning(SFStep2Constants.InvalidName, null)
            isValid = false;
        }
        if (!filteredRecipients.map((r, i) => {
            return isValidEmailAddress(r.emailAddress)
        }).every((x: boolean) => x == true)) {
            VenusNotifier.Warning(SFStep2Constants.InvalidEmail, null);
            isValid = false;
        }
        if (!filteredRecipients.map((r, i) => {
            return r.clientType !== ClientType.Undefined
        }).every((x: boolean) => x == true)) {
            VenusNotifier.Warning(SFStep2Constants.RecipientTypeWarning, null);
            isValid = false;
        }

        filteredRecipients.forEach((recipient) => {
            if (!isAuthenticationValid(recipient)) {
                isValid = false;
                return;
            }
        });

        return isValid;
    }

    const isLoadingCheck = () => {
        let isLoading = false;
        if (props.contacts.length === 0 ||
            props.signingInfo.recipients.length === 0 ||
            signingInfo.recipients.length === 0 ||
            props.isLoading) {
            isLoading = true;
        }
        else if (props.signingInfo.recipients[0].clientGuid !== signingInfo.recipients[0].clientGuid) {
            isLoading = true;
        }
        return isLoading;
    }

    const isAuthenticationEditableCheck = () => {
        let isAuthenticationEditable = false;
        if (props.selectedDocument &&
            (props.selectedDocument.signatureStatus === SignatureFlowReportSignatureStatus.OutForSignature ||
                props.selectedDocument.signatureStatus === SignatureFlowReportSignatureStatus.PartiallySigned) &&
            (props.signingInfo.signerAuthentication || props.signingInfo.isAccessCodeAuthenticationRequired)) {
                isAuthenticationEditable = true;
        }
        else if (props.selectedDocument &&
            props.selectedDocument.signatureStatus === SignatureFlowReportSignatureStatus.OutForSignature &&
            !props.signingInfo.signerAuthentication) {
                isAuthenticationEditable = props.signingInfo.recipients.some(function (item) {
                return item.authenticationType.toString() !== AuthenticationType.None.toString();
            });
        }
        return isAuthenticationEditable;
    }

    const getRecipients = () => {

        // make signing order of delegator same as delegatee to display in edit client details
        setDelegatorsOrder();
        return signingInfo.recipients.filter(r => r.clientType !== ClientType.Sender && r.clientType !== ClientType.Delegatee);
    }

    const handleChnageClientID = (event: any) => {
        let newSigningInfo = Object.assign({}, signingInfo);
        newSigningInfo.clientId = event.target.value;
        setSigningInfo(newSigningInfo);
    }

    const handleChnageClientName = (event: any) => {
        let newSigningInfo = Object.assign({}, signingInfo);
        newSigningInfo.recipientName = event.target.value;
        setSigningInfo(newSigningInfo);
    }

    const handleChangeOfficeLocation = (value : number) => {
        let newSigningInfo = Object.assign({}, signingInfo);
        newSigningInfo.locationId = value;
        setSigningInfo(newSigningInfo);
    }

    const isLoading = isLoadingCheck();
    const isAuthenticationEditable = isAuthenticationEditableCheck();
    

    return <div>
            <Modal 
                centered={true}
                show={props.show}
                onHide={props.onCancel}
                className="edit-client-info-modal"
                backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Client Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='custom-scroll-bar'>
                    <LoadingOverlay style={{ height: '50%' }} >
                        <Loader loading={isLoading} />
                        <div className='editClientInformation marB45'>
                            <Col sm={12} className='fontWeight700 fontSize15 marB10 marL05'>
                                <div>Client Information</div>
                            </Col>
                            <Col sm = {12}  className='fontWeight600 fontSize14' style={{margin: "15px 0px 5px 10px"}}>
                                <Row className='editClientInformationText'>
                                    <Col sm={3} className={props.companySettings.clientIdSettings.mandateClientId ? '' : 'padT05'}> 
                                        <div>Client ID {props.companySettings.clientIdSettings.mandateClientId && <span><Asterisk/></span>}</div>
                                    </Col>

                                    <Col sm={9} className='padL00'>
                                        <div> Client Name <span><Asterisk/></span></div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm = {12}  className='fontWeight600 fontSize14' style={{margin: "0px 0px 6px 8px"}}>
                                <Row>
                                    <Col sm={3}>
                                        <FormControl
                                            type="text"
                                            placeholder="Client ID"
                                            value={signingInfo.clientId}
                                            title={signingInfo.clientId}
                                            onChange={(event: any) => { handleChnageClientID(event) }}
                                            autoFocus={true}
                                            data-test-auto="966ba71c-db53-4234-b1d0-a8c77ea44550"
                                        />
                                    </Col>

                                    <Col sm={9} className='padL00'>
                                        <FormControl
                                            type="text"
                                            placeholder="Client Name"
                                            value={signingInfo.recipientName}
                                            title={signingInfo.recipientName}
                                            onChange={(event: any) => { handleChnageClientName(event) }}
                                            autoFocus={true}
                                            data-test-auto="00b8c9e1-3b8c-40a5-a351-a95be1dfa981"
                                        />
                                    </Col>                                    
                                </Row>
                            </Col>

                            <Col sm={12} className='fontWeight600 fontSize14' style={{margin: "15px 0px 5px 10px"}}>
                                <Row className='editClientInformationText'>
                                    <Col sm={4} >
                                        <div> Office Location <span><Asterisk/></span></div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm={12} className='fontWeight600 fontSize14' style={{margin: "0px 0px 6px 8px"}}>
                                <Row>
                                    <Col sm={7}>
                                            <DropdownComponent
                                                id="editClientOfficeLocationDropdown"
                                                options={props.officeLocationList}
                                                onChange={(value: any) => { handleChangeOfficeLocation(value)  }}
                                                selectedValue={signingInfo.locationId}
                                                arrowRenderer={()=><DropdownArrow/>}
                                                searchable={false}
                                                clearable={false}
                                                customPlaceHolder='Select Office Location'
                                                data-test-auto={"55729ce3-194e-43bb-b480-6558227c47c8"}
                                                />
                                    </Col>
                                </Row>
                            </Col>
                        </div>    
                        <ClientAuthenticationList
                            ref={editClientAuthenticationListRef}
                            contacts={props.contacts}
                            clientDetailsMode={ClientDetailsMode.Edit}
                            recipientDeletable={false}
                            isEditable={isAuthenticationEditable}
                            disableRecipientType={true}
                            questions={props.questions}
                            updateRecipient={updateRecipient}
                            recipients={getRecipients()}
                            signingType={props.signingInfo.signingType}
                            signatureReport={props.selectedDocument}
                            securitySettings={props.companySettings.securitySettings}
                            clientAuthenticationData={props.clientAuthenticationData}
                            getAuthenticationParameter={props.getAuthenticationParameter}
                        />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='default'
                        className='btn-white modal-footer-button-cancel'
                        onClick={onCancelButtonClick}
                        data-test-auto="90A9BBDE-5FC8-405A-A681-90E4E47F5631">
                         Cancel
                    </Button>
                    <Button
                        variant='info'
                        className=' modal-footer-button-save'
                        onClick={onSave}
                        disabled={isLoading}
                        data-test-auto="58CCAEDA-F7CC-41AE-AFC1-4688CD67A0A9">
                         Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
};

