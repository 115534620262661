import * as React from 'react';
import * as Bs from 'react-bootstrap';
import 'isomorphic-fetch';
import { Link } from 'react-router-dom';
import { ISignatureFlowReportDocument, SignatureFlowReportSignatureStatus } from '../../models/SignatureFlowReportState';
import { IUserProfile } from '../../components/navigation/profile/ProfileObjects';
import { ResourceIdConstants } from '../helper/RBACConstants';
import { Button } from 'react-bootstrap';
import { ActionDropDownButton } from '../svg/CustomSvgIcons';


interface ActionProps {
    screenId: string;
    isArchive: boolean;
    title: string;
    rowIndex: number;
    onDocumentNoteOpen: (rowIndex: number) => void;
    onDownloadReturnOpen: (rowIndex: number) => void
    onClientTrackingOpen: (rowIndex: number) => void;
    onReportProblemOpen: (rowIndex: number) => void;
    onSetAccessOpen: (rowIndex: number, headerTextShow: boolean) => void;
    onResendAccessLinkOpen: (rowIndex: number) => void;
    onViewAccessCodeOpen: (rowIndex: number) => void;
    onCancelDocumentConfirmation: (rowIndex: number) => void;
    onDeleteDocumentConfirmation: (rowIndex: number) => void;
    onSendReminder: (rowIndex: number) => void;
    onEditClientDetails: (rowIndex: number) => void;
    onUnlockRecipientsOpen: (rowIndex: number) => void;
    signatureReportsData: ISignatureFlowReportDocument[];
    userBasicProfile: IUserProfile;
}

export class SignatureFlowReportAction extends React.Component<ActionProps, {}> {
    private createPrimaryActions = () => {

        const { screenId } = this.props;
        return (<div>
            <span
                data-resource-id={`${screenId}${ResourceIdConstants.Download}`}
                onClick={() => this.props.onDownloadReturnOpen(this.props.rowIndex)}
                title={this.props.signatureReportsData[this.props.rowIndex].signatureStatus != SignatureFlowReportSignatureStatus.DeliveryFailed ?
                    "Download Documents" : "Delivery Failed documents cannot be downloaded"}
                style={this.props.signatureReportsData[this.props.rowIndex].signatureStatus == SignatureFlowReportSignatureStatus.DeliveryFailed ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}>
                <a style={{color:'#05386B'}}>Download</a>
            </span>
                <span style={{color: '#C7C8C9', paddingLeft:'10px'}}>
                    <svg width="2" height="16" viewBox="0 0 2 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="0" height="16" transform="translate(1)" fill="white" fillOpacity="0.01" />
                        <path d="M1 0V16" stroke="#212529" strokeOpacity="0.25" />
                    </svg>
                </span>

            

            {this.createSecondaryActions()}
        </div >);
    }

    private createSecondaryActions = () => {
        const { screenId } = this.props;
        return (<Bs.ButtonToolbar className="btn-group-vertical" title={"More.."} >
            <Bs.DropdownButton 
                size="sm"
                title={<ActionDropDownButton />}
                id="delivered-return-dropdown"
                alignRight
            >
            {
                (this.props.signatureReportsData[this.props.rowIndex].hasLockedUteEmail || 
                    this.props.signatureReportsData[this.props.rowIndex].hasDirectAccessLockedAccount) && 
                <li title="Unlock Recipient(s)">
                <Button
                    variant='none'
                    active={false}
                    className='action-dropdown-btn'
                    data-resource-id={`${screenId}${ResourceIdConstants.UnlockDirectAccessLockedEmails}`}
                    onClick={()=>this.props.onUnlockRecipientsOpen(this.props.rowIndex)}>
                    <span className='filter-dropdown-text'>Unlock Recipient(s)</span>
                </Button>
            </li>
            }
           
            <li title="Resend Access Link">
                <Button
                variant='none'
                className='action-dropdown-btn'
                data-resource-id={`${screenId}${ResourceIdConstants.ResendAccess}`}
                disabled={!this.canAccessResendAccessLinkModal()}
                    onClick={()=>this.props.onResendAccessLinkOpen(this.props.rowIndex)}>
                   <span className='filter-dropdown-text'>Resend Access Link</span>

                </Button>
            </li>
            <li>
                <Button
                    variant="none"
                    title={"Set Access"}
                    type="button"
                    className="action-dropdown-btn"
                    data-resource-id={`${screenId}${ResourceIdConstants.SetAccess}`}
                    onClick={(e) => this.props.onSetAccessOpen(this.props.rowIndex, false)}>
                    <span className='filter-dropdown-text'>Set Access</span>
                </Button>
            </li>
            
            <li>
                <Button
                    variant="none"
                    title={"Cancel Document"}
                    type="button"
                    className="action-dropdown-btn"
                    data-resource-id={`${screenId}${ResourceIdConstants.Cancel}`}
                    onClick={(e) => this.props.onCancelDocumentConfirmation(this.props.rowIndex)}>
                        <span className='filter-dropdown-text'>Cancel Document</span>
                </Button>
            </li>

                {this.props.signatureReportsData[this.props.rowIndex].signatureStatus != SignatureFlowReportSignatureStatus.Cancelled &&
                    this.props.signatureReportsData[this.props.rowIndex].signatureStatus != SignatureFlowReportSignatureStatus.SignatureStampingFailed &&
                    this.props.signatureReportsData[this.props.rowIndex].signatureStatus != SignatureFlowReportSignatureStatus.DeliveryFailed ?
                    <li title="View Access Code"
                        data-resource-id={`${screenId}${ResourceIdConstants.ViewAccessCode}`}                        >
                        <Button  variant="none" type="button" className="action-dropdown-btn" onClick={() => this.props.onViewAccessCodeOpen(this.props.rowIndex)} >
                            <span className='filter-dropdown-text'>View Access Code</span>
                        </Button>
                    </li> : ''
                }

                {this.props.signatureReportsData[this.props.rowIndex].signatureStatus != SignatureFlowReportSignatureStatus.Cancelled &&
                    this.props.signatureReportsData[this.props.rowIndex].signatureStatus != SignatureFlowReportSignatureStatus.ESigned &&
                    this.props.signatureReportsData[this.props.rowIndex].signatureStatus != SignatureFlowReportSignatureStatus.Declined &&
                    this.props.signatureReportsData[this.props.rowIndex].signatureStatus != SignatureFlowReportSignatureStatus.SignatureStampingFailed &&
                    this.props.signatureReportsData[this.props.rowIndex].signatureStatus != SignatureFlowReportSignatureStatus.DeliveryFailed ?
                        <li title="Send Reminder"
                            data-resource-id={`${screenId}${ResourceIdConstants.SendReminder}`}>
                        <Button variant="none" type="button" className="action-dropdown-btn" onClick={() => this.props.onSendReminder(this.props.rowIndex)}>
                        <span className='filter-dropdown-text'>Send Reminder</span></Button>
                    </li> : ''
                }

                <li title="Document Note"
                    data-resource-id={`${screenId}${ResourceIdConstants.DocumentNote}`}>
                    <Button variant="none" type="button" className="action-dropdown-btn" onClick={() => this.props.onDocumentNoteOpen(this.props.rowIndex)} >
                    <span className='filter-dropdown-text'>Document Note</span></Button>
                </li>

                <li title="Client Tracking"
                    data-resource-id={`${screenId}${ResourceIdConstants.ClientTracking}`}>
                    <Button variant="none" type="button" className="action-dropdown-btn" onClick={() => this.props.onClientTrackingOpen(this.props.rowIndex)} >
                    <span className='filter-dropdown-text'>Client Tracking</span></Button>
                </li>

                    <li title="Edit Client Details"
                    data-resource-id={`${screenId}${ResourceIdConstants.EditClientDetails}`}                    >
                    <Button variant="none" type="button" className="action-dropdown-btn" onClick={() => this.props.onEditClientDetails(this.props.rowIndex)}>
                    <span className='filter-dropdown-text'>Edit Client Details</span></Button>
                </li>
                
                    <li title="Report a Problem"
                    data-resource-id={`${screenId}${ResourceIdConstants.ReportAProblem}`}                    >
                    <Button variant="none" type="button" className="action-dropdown-btn" onClick={() => this.props.onReportProblemOpen(this.props.rowIndex)}>
                    <span className='filter-dropdown-text'>Report a Problem</span></Button>
                </li>

                <li title="Delete Document"
                    className="delete document"
                    data-resource-id={`${screenId}${ResourceIdConstants.Delete}`}>
                    <Button variant="none" type="button" className="action-dropdown-btn" onClick={() => this.props.onDeleteDocumentConfirmation(this.props.rowIndex)} >
                    <span className='filter-dropdown-text'>Delete Document</span></Button>

                </li> 
            </Bs.DropdownButton>
        </Bs.ButtonToolbar >);
    }

    private canAccessResendAccessLinkModal = () => {
        const signatureStatus: SignatureFlowReportSignatureStatus = this.props.signatureReportsData[this.props.rowIndex].signatureStatus;
        return [
            SignatureFlowReportSignatureStatus.OutForSignature,
            SignatureFlowReportSignatureStatus.PartiallySigned,
            SignatureFlowReportSignatureStatus.ESigned,
        ].includes(signatureStatus);
    }

    public render() {

        return <div className="button-group">{this.createPrimaryActions()}</div>;
    }
}