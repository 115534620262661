import { API_BASE_URL } from "../../../utils/AppConstants";

const BaseUrl = `${API_BASE_URL}api/SignatureFlow/`;

export const SignatureReportPaths = {
    SetAccess: `${BaseUrl}ReportAccessor/Signature/SetAccess`,
    Download: `${BaseUrl}SignatureFlowReportPopup/Signature/Download`,
    DownloadAll: `${BaseUrl}SignatureFlowReportPopup/Signature/DownloadAll`,
    CancelDescription: `${BaseUrl}SignatureFlowReportPopup/Signature/Cancel/Description`,
    UpdateStatusAsCancel: `${BaseUrl}SignatureFlowReportPopup/Signature/Cancel/UpdateStatus`,
    GetOtp: `${BaseUrl}SignatureFlowReportPopup/Signature/Otp`,
    GenerateOtp: `${BaseUrl}SignatureFlowReportPopup/Signature/Otp/Generate`,
    CancelNotification: `${BaseUrl}SignatureFlowMailEvents/Signature/Cancel`,
    ClientTracking: `${BaseUrl}SignatureFlowReportPopup/Signature/ClientTracking`,
    Delete: `${BaseUrl}SignatureFlowReportPopup/Signature/Delete`,
    SendReminder: `${BaseUrl}SignatureFlowMailEvents/Signature/SendReminder`,
    ResendAccesslink: `${BaseUrl}SignatureFlowMailEvents/Signature/ResendAccesslink`,
    ReportedProblem: `${API_BASE_URL}api/SupportIssue/Signature/ReportedProblem`,
    UpdateReminderSettings: `${BaseUrl}SigningInfoSettings/Signature/ReminderSettings`,
    DocumentNote: `${BaseUrl}SignatureFlowReports/Signature/DocumentNote`,
    UpdateAuthentication: `${BaseUrl}SendForSignature/Signature/ClientAuthentication`,
    SigningInfo: `${BaseUrl}SendForSignature/Signature/SigningInfo`,
    SigningInfoWithDocuments:`${BaseUrl}SendForSignature/Signature/SigningInfoDocument`,
    UpdateDocumentSigningInfo:`${BaseUrl}SendForSignature/Signature/UpdateDocumentSigningInfo`,
    ChangeOfficeLocation: `${API_BASE_URL}api/office-location/Signature/change-office-location`,
    UnlockRecipients: `${BaseUrl}SignatureFlowReportPopup/Signature/UnlockDirectAccessLockedAccounts`,
}

export const ArchiveReportPaths = {
    SetAccess: `${BaseUrl}ReportAccessor/Archive/SetAccess`,
    Download: `${BaseUrl}SignatureFlowReportPopup/Archive/Download`,
    DownloadAll: `${BaseUrl}SignatureFlowReportPopup/Archive/DownloadAll`,
    CancelDescription: `${BaseUrl}SignatureFlowReportPopup/Archive/Cancel/Description`,
    UpdateStatusAsCancel: `${BaseUrl}SignatureFlowReportPopup/Archive/Cancel/UpdateStatus`,
    GetOtp: `${BaseUrl}SignatureFlowReportPopup/Archive/Otp`,
    GenerateOtp: `${BaseUrl}SignatureFlowReportPopup/Archive/Otp/Generate`,
    CancelNotification: `${BaseUrl}SignatureFlowMailEvents/Archive/Cancel`,
    ClientTracking: `${BaseUrl}SignatureFlowReportPopup/Archive/ClientTracking`,
    Delete: `${BaseUrl}SignatureFlowReportPopup/Archive/Delete`, 
    SendReminder: `${BaseUrl}SignatureFlowMailEvents/Archive/SendReminder`,
    ResendAccesslink: `${BaseUrl}SignatureFlowMailEvents/Archive/ResendAccesslink`,
    ReportedProblem: `${API_BASE_URL}api/SupportIssue/Archive/ReportedProblem`,
    UpdateReminderSettings: `${BaseUrl}SigningInfoSettings/Archive/ReminderSettings`,
    DocumentNote: `${BaseUrl}SignatureFlowReports/Archive/DocumentNote`,
    UpdateAuthentication: `${BaseUrl}SendForSignature/Archive/ClientAuthentication`,
    SigningInfo: `${BaseUrl}SendForSignature/Archive/SigningInfo`,
    UpdateDocumentSigningInfo:`${BaseUrl}SendForSignature/Archive/UpdateDocumentSigningInfo`,
    ChangeOfficeLocation: `${API_BASE_URL}api/office-location/Archive/change-office-location`,
    UnlockRecipients: `${BaseUrl}SignatureFlowReportPopup/Archive/UnlockDirectAccessLockedAccounts`,
}

export const DraftReportPaths = {
    SetAccess: `${BaseUrl}ReportAccessor/Draft/SetAccess`,
    AssignedTo: `${BaseUrl}DocumentAssignment/Draft/AssignTo`,
    ReportedProblem: `${API_BASE_URL}api/SupportIssue/Draft/ReportedProblem`,
    IsDocumentsLocked:`${BaseUrl}/SendForSignature/IsDocumentsLocked`
}

export const CommonPaths = {
    DeclinedDescription: `${BaseUrl}SignatureFlowReportPopup/Declined/Description`,
    ReportedProblem: `${API_BASE_URL}api/SupportIssue/ReportedProblem`
}
